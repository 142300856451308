import { types } from 'mobx-state-tree'

import {
  Auth,
  Account,
  AccountInsight,
  Accounts,
  Breakdown,
  Config,
  CommunicationPreferences,
  Person,
  Spouse,
  Dependents,
  ContactInfo,
  Documents,
  Engine,
  Features,
  Goals,
  Login,
  StockOptions,
  UserLogs,
  UserSettings,
  PortfolioConstruction,
  Plaid,
  Plan,
  SpendDown,
  SocialSecurity,
  RetailStore,
  CatchupRules,
} from './models'

const { compose } = types

const AppStore = compose(
  Auth,
  Account,
  AccountInsight,
  Accounts,
  Breakdown,
  CatchupRules,
  Config,
  CommunicationPreferences,
  Person,
  Spouse,
  Dependents,
  ContactInfo,
  Documents,
  Engine,
  Features,
  Goals,
  Login,
  StockOptions,
  UserLogs,
  UserSettings,
  PortfolioConstruction,
  Plaid,
  Plan,
  SpendDown,
  SocialSecurity,
  RetailStore
)

export default AppStore
