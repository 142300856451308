import { types, flow } from 'mobx-state-tree'

import { API } from '../api'
const { model, maybeNull, number, array } = types

const CatchupRuleId = model({
  year: maybeNull(number),
  minimumAge: maybeNull(number),
  maximumAge: maybeNull(number),
})

const CatchupRuleLimit = model({
  id: CatchupRuleId,
  maximumAmount: maybeNull(number),
})

const CatchupRules = model({
  standardLimit: maybeNull(number),
  catchupLimits: array(CatchupRuleLimit),
  standardCatchup: maybeNull(CatchupRuleLimit),
  additionalCatchup: maybeNull(CatchupRuleLimit),
}).actions(self => ({
  getLimits: flow(function* () {
    const { data } = yield API.get('federal-limit')
    self.standardLimit = data.dlr401k
    self.catchupLimits = data.catchupRulesLimitsList
    self.standardCatchup = data.catchupRulesLimitsList.find(x => x.id.minimumAge === 50)
    self.additionalCatchup = data.catchupRulesLimitsList.find(x => x.id.minimumAge === 60)
  }),
}))

export default CatchupRules
