/* eslint-disable react/jsx-handler-names */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */

import { css } from '@emotion/react'
import { useState } from 'react'
import { Provider, inject, observer } from 'mobx-react'
import { Form, useField } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import * as yup from 'yup'
import styled from '@emotion/styled'
import posed, { PoseGroup } from 'react-pose'
import { PatternFormat } from 'react-number-format'
import _ from 'lodash'

import { TextInput, Button, DatePicker, HelpIcon, TooltipText, FormError } from '../../components'
import { RadioGroup, RadioButton } from '../../guided-toolbox'
import { Page, Header } from './elements'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

function SetupAccount(props) {
  const [oneUpperCase, setOneUpperCase] = useState(false)
  const [oneLowerCase, setOneLowerCase] = useState(false)
  const [oneNumber, setOneNumber] = useState(false)
  const [oneSpecialCharacter, setOneSpecialCharacter] = useState(false)
  const [minlength, setMinLength] = useState(false)
  const [enableAuthMethodSelection] = useState(false)

  const {
    store: { registerAccount },
  } = props
  const { location, history } = props

  const handleRegisterAccount = async values => {
    try {
      await registerAccount(values)
      history.push('/atmos/register/enter-auth-code/set-up-2FA')
    } catch (err) {
      console.error(err)
      return {
        [FORM_ERROR]:
          'Something went wrong. Please check your information and try again. If the problem persists, please contact GuidedChoice.',
      }
    }
  }

  const onSubmit = async values => {
    return handleRegisterAccount(values)
  }

  const initialValues = {
    twoFactorType: 'email',
    ssn: undefined,
    birthDate: undefined,
    hireDate: undefined,
    password: undefined,
    confirmPassword: undefined,
  }

  const validate = values => {
    const errors = {}
    setOneUpperCase(false)
    setOneLowerCase(false)
    setOneNumber(false)
    setOneSpecialCharacter(false)
    setMinLength(false)

    if (!values.twoFactorType) {
      errors.twoFactorType = 'Required'
    }

    if (values.twoFactorType === 'phone') {
      if (values.mobilePhone === undefined || values.mobilePhone === '') {
        errors.mobilePhone = 'Mobile number is required to receive two-factor authentication code'
      }
    }

    if (values.password && values.password.length > 7) {
      setMinLength(true)
    }

    if (values.password) {
      const result = values.password.match(/[0-9]/g)
      if (result !== null) Array.from(result)
      if (Array.isArray(result) && result.length > 0) {
        setOneNumber(true)
      }
    }

    if (values.password) {
      const result = values.password.match(/[a-z]/g)
      if (result !== null) Array.from(result)
      if (Array.isArray(result) && result.length > 0) {
        setOneLowerCase(true)
      }
    }

    if (values.password) {
      const result = values.password.match(/[A-Z]/g)
      if (result !== null) Array.from(result)
      if (Array.isArray(result) && result.length > 0) {
        setOneUpperCase(true)
      }
    }

    if (values.password) {
      const result = values.password.match(/[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g)
      if (result !== null) Array.from(result)
      if (Array.isArray(result) && result.length > 0) {
        setOneSpecialCharacter(true)
      }
    }

    if (!oneUpperCase || !oneLowerCase || !oneNumber || !oneSpecialCharacter || !minlength) {
      errors.password = 'Your password must meet all the above requirements'
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Confirm password must match password'
    }
    return errors
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <Provider loginAccount={props.store.loginStore}>
      <PoseGroup animateOnMount>
        <RouteContainer key={location.key || location.pathname}>
          <Page.Container>
            <Header />
            <Page>
              <Page.Heading title='Set up GuidedChoice account' />
              <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                subscription={{
                  submitting: true,
                  submitError: true,
                  errors: true,
                  hasSubmitErrors: true,
                  hasValidationErrors: true,
                  invalid: true,
                  pristine: true,
                }}
                validate={validate}
                render={({ handleSubmit, submitting, submitError, values }) => (
                  <Page.Form>
                    <Page.AnimateGroup>
                      <Page.AnimateItem
                        css={css`
                          padding-left: 20px;
                        `}>
                        <BorderedCard>
                          <CardTitle>Create profile</CardTitle>
                          <CardBody>
                            <FieldWrapper>
                              <Label htmlFor='ssn'>Social Security number</Label>
                              <HTMLField>
                                <SSNField />
                              </HTMLField>
                            </FieldWrapper>

                            <FieldWrapper>
                              <Label htmlFor='birthDate'>Birthdate</Label>
                              <HTMLField>
                                <BirthDateField />
                              </HTMLField>
                            </FieldWrapper>

                            <FieldWrapper>
                              <Label htmlFor='hireDate'>Hire date</Label>
                              <HTMLField>
                                <HireDateField />
                              </HTMLField>
                            </FieldWrapper>

                            <FieldWrapper>
                              <Label htmlFor='mobilePhone'>
                                Mobile phone <HelpIcon tooltip={TooltipText.atmosMobileNumber()} />
                              </Label>

                              <HTMLField>
                                <MobilePhoneField />
                              </HTMLField>
                            </FieldWrapper>
                          </CardBody>
                        </BorderedCard>

                        <BorderedCard>
                          <CardTitle>Set password</CardTitle>
                          <Page.AnimateItem style={{ alignSelf: 'flex-start' }}>
                            <Page.Text>Password must meet the following requirements:</Page.Text>
                            <PasswordRequirements>
                              <Page.Text>
                                {oneUpperCase ? <CheckMark>&#10003;</CheckMark> : <span />} at least
                                1 upper case letter
                              </Page.Text>
                              <Page.Text>
                                {oneLowerCase ? <CheckMark>&#10003;</CheckMark> : <span />} at least
                                1 lower case letter
                              </Page.Text>
                              <Page.Text>
                                {oneNumber ? <CheckMark>&#10003;</CheckMark> : <span />} at least 1
                                number
                              </Page.Text>
                              <Page.Text>
                                {oneSpecialCharacter ? <CheckMark>&#10003;</CheckMark> : <span />}{' '}
                                at least 1 special character
                              </Page.Text>
                              <Page.Text>
                                {minlength ? <CheckMark>&#10003;</CheckMark> : <span />} at least 8
                                characters in length
                              </Page.Text>
                            </PasswordRequirements>
                          </Page.AnimateItem>
                          <CardBody>
                            <FieldWrapper>
                              <Label htmlFor='password'>Password</Label>
                              <HTMLField>
                                <PasswordField />
                              </HTMLField>
                            </FieldWrapper>

                            <FieldWrapper>
                              <Label htmlFor='confirmPassword'>Confirm password</Label>
                              <HTMLField>
                                <ConfirmPasswordField />
                              </HTMLField>
                            </FieldWrapper>
                          </CardBody>
                        </BorderedCard>

                        {enableAuthMethodSelection && (
                          <BorderedCard>
                            <div
                              css={css`
                                display: flex;
                                align-self: flex-start;
                                flex-wrap: wrap;
                                align-items: center;
                              `}>
                              <CardTitle
                                css={css`
                                  word-break: break-word;
                                `}>
                                Select authentication method
                              </CardTitle>{' '}
                              &nbsp; &nbsp;
                              <HelpIcon tooltip={TooltipText.authenticationMethod()} />
                            </div>
                            <AuthenticationRadioGroup />
                          </BorderedCard>
                        )}

                        {submitError && <FormError err={submitError} />}
                      </Page.AnimateItem>

                      <div
                        css={css`
                          display: flex;
                          justify-content: space-between;
                          margin-top: 30px;
                        `}>
                        <Button
                          onClick={handleCancel}
                          style={{ width: 124 }}
                          label='Cancel'
                          type='button'
                          secondary
                        />
                        <Button
                          onClick={handleSubmit}
                          label='Next'
                          type='submit'
                          primary
                          disabled={submitting}
                          style={{ width: 124 }}
                        />
                      </div>
                    </Page.AnimateGroup>
                  </Page.Form>
                )}
              />
            </Page>
          </Page.Container>
        </RouteContainer>
      </PoseGroup>
    </Provider>
  )
}

export default inject('store')(observer(SetupAccount))

const BorderedCard = styled.div`
  border: 6px solid #c4d7e0;
  border-radius: 8px;
  background-color: #ffffff;
  margin: 20px 0;
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const CardTitle = styled.div`
  color: ${p => p.theme.mediumGray};
  font-size: 36px;
  line-height: 46px;
  font-family: 'Open Sans', 'Helvetica Neue', 'sans-serif';
  font-weight: 300;
  margin-bottom: 12px;
  align-self: flex-start;
  word-break: break-word;
`

const PasswordRequirements = styled.div`
  align-self: flex-start;
  word-break: break-word;
`

const CheckMark = styled.span`
  color: green;
`

const CardBody = styled.div`
  max-width: 500px;
  width: 100%;
`

const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`

const Label = styled.label`
  color: #7a8e96;
  font-size: 18px;
  font-family: 'Open Sans';
  flex: 1;
  text-align: right;
  padding-right: 20px;
`

const HTMLField = styled.div`
  flex: 1;
`

const Text = styled.input`
  outline: none;
  border: none;
  max-width: 100%;
  color: #022a3a;
  background-color: transparent;
  -webkit-transition: border-bottom 0.3s;
  transition: border-bottom 0.3s;
  font-size: 1.125rem;
  line-height: 1rem;
  border-bottom: 1.5px solid #7a8e96;
  padding: 5px 0;
  max-width: 220px;
`

const ErrText = styled.input`
  outline: none;
  border: none;
  width: 100%;
  max-width: 100%;
  color: #022a3a;
  background-color: transparent;
  -webkit-transition: border-bottom 0.3s;
  transition: border-bottom 0.3s;
  font-size: 1.125rem;
  line-height: 1.5rem;
  border-bottom: 1.5px solid #e31e27;
  padding: 10px 0;
  max-width: 220px;
`

const FieldErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Error = styled.span`
  color: #e31e27;
  font-size: 14px;
  padding-top: 2px;
  width: 100%;
`

function SSNField() {
  const { input, meta } = useField('ssn', {
    validate: value =>
      yup
        .string()
        .required('Social Security Number is required')
        .max(11, 'Maximum character limit 9 exceeded')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <FieldErrorWrapper>
      <PatternFormat
        format='###-##-####'
        {...input}
        customInput={(meta.error || meta.submitError) && meta.touched ? ErrText : Text}
        placeholder='###-##-####'
      />
      {(meta.error || meta.submitError) && meta.touched && (
        <Error>{meta.error || meta.submitError}</Error>
      )}
    </FieldErrorWrapper>
  )
}

function MobilePhoneField() {
  const { input, meta } = useField('mobilePhone')
  return (
    <FieldErrorWrapper>
      <PatternFormat
        format='(###) ###-####'
        {...input}
        customInput={(meta.error || meta.submitError) && meta.touched ? ErrText : Text}
        placeholder='(###) ###-####'
      />
      {(meta.error || meta.submitError) && meta.touched && (
        <Error>{meta.error || meta.submitError}</Error>
      )}
    </FieldErrorWrapper>
  )
}

function BirthDateField() {
  const { input, meta } = useField('birthDate', {
    validate: value =>
      yup
        .string()
        .required('Birthdate is required')
        .max(11, 'Maximum character limit 11 exceeded')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <DatePicker
      handleChange={(name, value) => input.onChange(value)}
      maxYear={new Date().getFullYear()}
      minYear={1910}
      name={input.name}
      date={input.value}
      showError={meta.touched}
      error={meta.error}
    />
  )
}

function HireDateField() {
  const { input, meta } = useField('hireDate', {
    validate: value =>
      yup
        .string()
        .required('Hire date is required')
        .max(11, 'Maximum character limit 11 exceeded')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <DatePicker
      handleChange={(name, value) => input.onChange(value)}
      maxYear={new Date().getFullYear()}
      minYear={1910}
      name={input.name}
      date={input.value}
      showError={meta.touched}
      error={meta.error}
      hideDay
    />
  )
}

function PasswordField() {
  const { input, meta } = useField('password', {
    validate: value =>
      yup
        .string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(32, 'Password cannot exceed 32 characters')
        .matches(/[0-9]/g, 'Password must include at least one number')
        .matches(/[a-z]/g, 'Password must include at least one lower case letter')
        .matches(/[A-Z]/g, 'Password must include at least one upper case letter')
        .matches(
          /[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g,
          'Password must include at least one special character'
        )
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      id='password'
      error={meta.error}
      type='password'
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      placeholder='Password'
      showError={meta.touched}
      value={input.value}
      style={{ maxWidth: 220 }}
      passwordRules
    />
  )
}

function ConfirmPasswordField() {
  const { input, meta } = useField('confirmPassword', {
    validate: value =>
      yup
        .string()
        .required('Confirm password is required')
        .validate(value)
        .then(_.noop)
        .catch(err => err.message),
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <TextInput
      id='confirmPassword'
      error={meta.error}
      type='password'
      name={input.name}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onChange={(name, value) => input.onChange(value)}
      placeholder='Password'
      showError={meta.touched}
      value={input.value}
      style={{ maxWidth: 220 }}
    />
  )
}

function AuthenticationRadioGroup() {
  const { input, meta } = useField('twoFactorType', {
    subscription: { value: true, touched: true, error: true },
  })

  return (
    <div
      css={css`
        margin: 16px;
      `}>
      <RadioGroup name='twoFactorType' value={input.value} onChange={input.onChange}>
        <RadioButton label='Send email to login email' value='email' />
        <RadioButton label='Send text to mobile' value='phone' />
      </RadioGroup>
      {meta.touched && meta.error && <Error>{meta.error}</Error>}
    </div>
  )
}
