import { inject, observer } from 'mobx-react'
import { css } from '@emotion/react'
import posed, { PoseGroup } from 'react-pose'
import { Header, Page } from '../elements'
import { Button, Row, Spacer } from '../../../components'
import { useEffect, useState } from 'react'
import { API, API_URL } from '../../../api'
import _ from 'lodash'
import LearnMoreDrawer from './learn-more/LearnMoreDrawer'
import { ProductOptions } from './RetailWelcome'

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
})

function RetailReview(props) {
  const {
    location,
    store: { selectPricebook, getPricebookId, clearPricebookSelection, get3nDomain },
  } = props
  const [pricebookOptions, setPricebookOptions] = useState([])
  const [organizationData, setOrganizationData] = useState(undefined)
  const [learnMoreDrawerOpen, setLearnMoreDrawerOpen] = useState(false)
  const [learnMorePricebook, setLearnMorePricebook] = useState(undefined)
  const [selectedPricebook, setSelectedPricebook] = useState(undefined)
  const [onlyOne, setOnlyOne] = useState(false)
  const [orgPays, setOrgPays] = useState(false)
  const [termsOfServiceLink, setTermsOfServiceLink] = useState(null)

  useEffect(() => {
    const getPricebookOptions = async () => {
      const id = getPricebookId()
      const { data } = await API.get('pricebook/available')
      const pricebook = data.find(pricebookBundle => pricebookBundle.pricebook.id === parseInt(id))
      if (pricebook) {
        setPricebookOptions([pricebook])
        setOnlyOne(true)
        setSelectedPricebook(pricebook)
      }
    }
    getPricebookOptions()
  }, [getPricebookId])

  useEffect(() => {
    const getOrganizationData = async () => {
      const { data } = await API.get('organization')
      setOrganizationData(data)
    }
    getOrganizationData()
  }, [])

  useEffect(() => {
    const getTermsLink = async () => {
      const res = await API.get('/termsAndConditions/docs/default')
      const termsObject = _.find(res.data, doc => {
        return doc.docDescription === 'Terms of Service'
      })
      const url = `${API_URL}termsAndConditions/default?docId=${termsObject.id}`
      setTermsOfServiceLink(url)
    }
    getTermsLink()
  }, [])

  useEffect(() => {
    setOrgPays(
      pricebookOptions?.filter(pricebook => pricebook.pricebook.paidBy === 'ORG')?.length > 0
    )
  }, [pricebookOptions])

  const handleCheckout = async () => {
    console.log(selectPricebook)
    if (selectedPricebook.pricebook.productType === 'GUIDED_CHOICE') {
      await selectPricebook(selectedPricebook.pricebook.id)
      window.location.replace(`${API_URL.slice(0, -4)}home`)
    } else {
      const domain3n = await get3nDomain()
      window.location.href = `https://app.${domain3n}/create-account?pricebookId=${selectedPricebook.pricebook.id}`
    }
  }

  const handleLogout = () => {
    clearPricebookSelection()
    window.open(`${API_URL.slice(0, -4)}logout`, '_self')
  }

  return (
    <PoseGroup animateOnMount>
      <RouteContainer key={location.key || location.pathname}>
        <LearnMoreDrawer
          pricebookBundle={learnMorePricebook}
          drawerOpen={learnMoreDrawerOpen}
          setDrawerOpen={setLearnMoreDrawerOpen}
        />
        <Page.Container Container>
          <Header
            css={css`
              padding: 0;
            `}
            organizationLogo={
              organizationData?.logoUri ? `${API_URL}/organization/logo` : undefined
            }
          />
          <Page
            css={css`
              align-items: flex-start;
            `}>
            <Page.Heading title='Review and check out' />
            <Page.SubHeading>
              You're almost there! Just take a second to review your subscription.
            </Page.SubHeading>
            <Spacer space='10px' />
            <ProductOptions
              pricebookOptions={pricebookOptions}
              selectedPricebook={selectedPricebook}
              setSelectedPricebook={onlyOne ? undefined : setSelectedPricebook}
              setDrawerOpen={setLearnMoreDrawerOpen}
              setLearnMorePricebook={setLearnMorePricebook}
            />
            <Page.FooterNote>
              Please see our{' '}
              <Page.Link href={termsOfServiceLink} target='_blank'>
                Terms of Service
              </Page.Link>{' '}
              {!orgPays && (
                <>
                  and{' '}
                  <Page.Link href='/retail/payment/cancellation-policy' target='_blank'>
                    Cancellation and Refund Policy
                  </Page.Link>{' '}
                </>
              )}
              for details
            </Page.FooterNote>
            <Spacer space='10px' />
            <Row
              css={css`
                justify-content: space-between;
                width: 100%;
              `}>
              <Button label='Cancel & log out' size='small' secondary onClick={handleLogout} />
              <Button
                label='Sign up'
                size='small'
                primary
                disabled={!selectedPricebook}
                onClick={handleCheckout}
              />
            </Row>
          </Page>
        </Page.Container>
      </RouteContainer>
    </PoseGroup>
  )
}

export default inject('store')(observer(RetailReview))
