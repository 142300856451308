/* eslint-disable import/no-anonymous-default-export */
import { css } from '@emotion/react'
import { Component } from 'react'
import styled from '@emotion/styled'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import { numberToDollars } from '../utils'
import { Spacer, SVGWrapper } from './'
import { PiggyBank, Avatar } from '../assets/icons'
import InlineSVG from 'svg-inline-react'
import avatar from '../assets/icons/AvatarSVG'

const AvatarBox = styled.div`
  display: inline-block;
  min-width: 28px;
  width: 28px;
  height: 28px;
  padding: 4px;
  & svg {
    transition: 0.35s;
    stroke: #7a8e96;
    fill: #7a8e96;
  }
`

export const ButtonContainer = styled.span`
  box-sizing: border-box;
  border: 1px solid #7a8e96;
  border-radius: 50px;
  padding: 0px 0px;
  width: 80px;
  height: 35px;
  background-color: #fff;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`
export const CustomButton = styled.button`
  box-sizing: border-box;
  padding: 4px 8px;
  border: none;
  background-color: ${({ active }) => (active ? '#85c964bd' : 'transparent')};
  border: ${({ active }) => (active ? '1px solid #7a8e96' : 'none')};
  outline: none;
  border-radius: 50px;
  margin: 0px -1px;
  color: #7a8e96;
  cursor: pointer;
  transform: ${p => (p.flipped ? 'scale(-1, -1)' : 'scale(1, 1)')};
  height: 38px;
  max-height: inherit;
  max-width: inherit;
`
const Heading = styled.h3`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.125rem;
  color: ${p => p.theme.mediumGray};
  padding: 16px 20px 4px 0;
  margin: 0;
`
const HeadingWithoutPadding = styled.h3`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.125rem;
  color: ${p => p.theme.mediumGray};
  margin: 0;
`

const InnerHeading = styled.span`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.125rem;
  color: ${p => p.theme.mediumGray};
  font-weight: 600;
`

const LeftPadding = styled.span`
  padding-left: 20px;
`

const SubHeading = styled.h4`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.125rem;
  color: ${p => p.theme.mediumGray};
  font-weight: normal;
  padding: 20px 0 0px 0;
  margin: 0;
`

const List = styled.ul`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.mediumGray};
`

export const TooltipText = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.mediumGray};
  overflow: auto;
  text-align: left;
  padding: 0 12px 6px;
  max-height: 80vh;
  max-width: 80vw;
`

const TooltipParagraph = styled.p`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.mediumGray};
  margin: 1rem 0;
`

const Bold = styled.span`
  font-weight: bold;
`

const Italics = styled.span`
  font-style: italic;
`

const Percent = styled.span`
  color: ${p => p.theme.lightestGray};
`

const Amount = styled.span`
  color: ${p => p.theme.darkestGray};
`

const DollarSign = styled.span`
  color: ${p => p.theme.lightestGray};
`

const StyledTable = styled.table`
  font-size: 0.875rem;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  color: ${p => p.theme.mediumGray};
  table-layout: fixed;
  width: 100%;
  min-width: ${p => (p.minWidth ? p.minWidth : '480px')};
  margin: 4px 0 0 0;
`

const TableHeader = styled.td`
  color: ${p => p.theme.mediumGray};
  text-decoration: underline;
  text-decoration-color: ${p => p.theme.mediumGray};
  font-size: 0.875rem;
  padding: ${p => (p.padding ? p.padding : '4px 0')};
  text-align: ${p => (p.textAlign ? p.textAlign : null)};
`

const TableCell = styled.td`
  padding: ${p => (p.padding ? p.padding : null)};
  text-align: ${p => (p.textAlign ? p.textAlign : null)};
`

const TableRow = styled.tr`
  padding: 2px 0;
`
const TableLabel = styled.td``

const MonthlyContributionRow = styled.div`
  padding: 12px 0 6px 0;
`

const MonthlyContributionName = styled.div`
  color: ${p => p.theme.mediumGray};
`

const differenceToDollars = value => {
  return (
    <span>
      {value >= 0 ? '+' : '-'}
      {StyledCurrency(value)}
    </span>
  )
}

const StyledCurrency = value => {
  return (
    <>
      <DollarSign>$</DollarSign>
      <Amount>{numberToDollars(Math.abs(value), true)}</Amount>
    </>
  )
}

class ProjectedIncomeComparisonBaseTable extends Component {
  render() {
    const { heading, headerRow, rowOne, rowTwo, rowThree } = this.props

    return (
      <>
        {heading && <SubHeading>{heading}</SubHeading>}
        <StyledTable>
          <tbody>
            <TableRow>
              <TableHeader>Market</TableHeader>
              <TableHeader>{headerRow[0]}</TableHeader>
              <TableHeader>{headerRow[1]}</TableHeader>
              <TableHeader>Difference</TableHeader>
            </TableRow>
            <TableRow>
              <TableLabel>Weak market</TableLabel>
              <td>{StyledCurrency(rowOne[0])}</td>
              <td>{StyledCurrency(rowOne[1])}</td>
              <td>{differenceToDollars(rowOne[1] - rowOne[0])}</td>
            </TableRow>
            <TableRow>
              <TableLabel>Average market</TableLabel>
              <td>{StyledCurrency(rowTwo[0])}</td>
              <td>{StyledCurrency(rowTwo[1])}</td>
              <td>{differenceToDollars(rowTwo[1] - rowTwo[0])}</td>
            </TableRow>
            <TableRow>
              <TableLabel>Strong market</TableLabel>
              <td>{StyledCurrency(rowThree[0])}</td>
              <td>{StyledCurrency(rowThree[1])}</td>
              <td>{differenceToDollars(rowThree[1] - rowThree[0])}</td>
            </TableRow>
          </tbody>
        </StyledTable>
      </>
    )
  }
}

const EmployerContributionLimits = inject('store')(
  observer(function (props) {
    const { limits } = props.store.plan
    const { catchupAge, catchupLimitAmount, year, dlr401k } = limits
    return (
      <TooltipText>
        <Heading>Contribution limits for employer-related plans (e.g.401(k), 403(b), 457)</Heading>
        <TooltipParagraph>
          You can contribute up to ${numberToDollars(dlr401k, true)} (plus a $
          {numberToDollars(catchupLimitAmount, true)} catch-up contribution for certain individuals
          aged {catchupAge} or over for plan year {year}), unless your employer's plan limits you to
          less. More information regarding limits can be found under Help: Accounts.
        </TooltipParagraph>
      </TooltipText>
    )
  })
)

const CatchupContributionLimits = inject('store')(
  observer(function (props) {
    return (
      <TooltipText>
        <Heading>Catch-up contributions</Heading>
        <TooltipParagraph>
          Catch-up contributions are additional contributions that individuals aged 50 and older can
          make to their retirement savings accounts beyond the standard contribution limits—assuming
          their plan rules permit catch-up contributions.
        </TooltipParagraph>
        <TooltipParagraph>
          Some recordkeeping systems require separate “buckets” for regular savings vs. catch-up
          contributions;{' '}
          <span
            css={css`
              font-weight: bold;
              font-style: italic;
            `}>
            if you are not contributing enough to reach the standard savings contribution limit for
            the current year, there is no need to enter a catch-up amount
          </span>
          , as the money is not taxed differently for regular savings vs. catch-up.
        </TooltipParagraph>
      </TooltipText>
    )
  })
)

const IraContributionLimits = inject('store')(
  observer(function (props) {
    const { limits } = props.store.plan
    const { catchupAge, iraAmount, catchupIraAmount, iraTotalCatchupLimit, year } = limits

    return (
      <TooltipText>
        <Heading>IRA contribution limit</Heading>
        <TooltipParagraph>
          The {year} IRA contribution limit is ${numberToDollars(iraAmount, true)}—or, if age{' '}
          {catchupAge} or over, you can contribute an additional $
          {numberToDollars(catchupIraAmount, true)} in catch-up contributions for a total $
          {numberToDollars(iraTotalCatchupLimit, true)}. The limit is a per person, not a per
          account limit. In other words, you can have a traditional IRA and Roth IRA, but your total
          contribution to both cannot exceed the limit.
        </TooltipParagraph>
      </TooltipText>
    )
  })
)

const ProjectedIncomeComparisonTableInitialAdvice = inject('store')(
  observer(
    class extends Component {
      render() {
        const { displayInflation } = this.props
        const { baseCase, recommendedCase } = this.props.store

        return (
          <>
            <ProjectedIncomeComparisonBaseTable
              heading="Using today's value"
              headerRow={['Current', 'Recommended']}
              rowOne={[
                _.get(baseCase, 'estimatedTotalMonthlyIncomeMin', 0),
                recommendedCase.estimatedTotalMonthlyIncomeMin,
              ]}
              rowTwo={[
                _.get(baseCase, 'estimatedTotalMonthlyIncome', 0),
                recommendedCase.estimatedTotalMonthlyIncome,
              ]}
              rowThree={[
                _.get(baseCase, 'estimatedTotalMonthlyIncomeMax', 0),
                recommendedCase.estimatedTotalMonthlyIncomeMax,
              ]}
            />
            {displayInflation && (
              <ProjectedIncomeComparisonBaseTable
                heading='Using value at retirement'
                headerRow={['Current', 'Recommended']}
                rowOne={[
                  _.get(baseCase, 'estimatedTotalMonthlyIncomeInflationAdjustedMin', 0),
                  recommendedCase.estimatedTotalMonthlyIncomeInflationAdjustedMin,
                ]}
                rowTwo={[
                  _.get(baseCase, 'estimatedTotalMonthlyIncomeInflationAdjusted', 0),
                  recommendedCase.estimatedTotalMonthlyIncomeInflationAdjusted,
                ]}
                rowThree={[
                  _.get(baseCase, 'estimatedTotalMonthlyIncomeInflationAdjustedMax', 0),
                  recommendedCase.estimatedTotalMonthlyIncomeInflationAdjustedMax,
                ]}
              />
            )}
          </>
        )
      }
    }
  )
)

const ProjectedIncomeComparisonTableModifiedAdvice = inject('store')(
  observer(
    class extends Component {
      render() {
        const { displayInflation } = this.props
        const { recommendedCase, modifiedCase } = this.props.store

        return (
          <>
            <ProjectedIncomeComparisonBaseTable
              heading="Using today's value"
              headerRow={['Recommended', 'Modified']}
              rowOne={[
                recommendedCase.estimatedTotalMonthlyIncomeMin,
                modifiedCase.estimatedTotalMonthlyIncomeMin,
              ]}
              rowTwo={[
                recommendedCase.estimatedTotalMonthlyIncome,
                modifiedCase.estimatedTotalMonthlyIncome,
              ]}
              rowThree={[
                recommendedCase.estimatedTotalMonthlyIncomeMax,
                modifiedCase.estimatedTotalMonthlyIncomeMax,
              ]}
            />
            {displayInflation && (
              <ProjectedIncomeComparisonBaseTable
                heading='Using value at retirement'
                headerRow={['Recommended', 'Modified']}
                rowOne={[
                  recommendedCase.estimatedTotalMonthlyIncomeInflationAdjustedMin,
                  modifiedCase.estimatedTotalMonthlyIncomeInflationAdjustedMin,
                ]}
                rowTwo={[
                  recommendedCase.estimatedTotalMonthlyIncomeInflationAdjusted,
                  modifiedCase.estimatedTotalMonthlyIncomeInflationAdjusted,
                ]}
                rowThree={[
                  recommendedCase.estimatedTotalMonthlyIncomeInflationAdjustedMax,
                  modifiedCase.estimatedTotalMonthlyIncomeInflationAdjustedMax,
                ]}
              />
            )}
          </>
        )
      }
    }
  )
)

const AdditionalSavingsData = inject('store')(
  observer(
    class extends Component {
      render() {
        const { modifiedCase } = this.props.store
        const engineType = modifiedCase ? 'modifiedCase' : 'recommendedCase'
        const engineData = this.props.store[engineType]
        const { noEmployerContributionList, sumNoEmployerContributionList } =
          this.props.store.accountInsight

        return (
          <>
            {!!sumNoEmployerContributionList && (
              <>
                <SubHeading>Other accounts already saving to</SubHeading>
                <TooltipParagraph>
                  Total other savings currently: {StyledCurrency(sumNoEmployerContributionList)} per
                  year | {StyledCurrency(sumNoEmployerContributionList / 12)} per month
                </TooltipParagraph>
                <List>
                  {noEmployerContributionList.map(account => (
                    <li key={account.accountName + account.accountId}>
                      {`${account.accountName}: $${numberToDollars(
                        account.annualContributionDollars,
                        true
                      )} per year | $${numberToDollars(
                        account.annualContributionDollars / 12,
                        true
                      )} per month`}
                    </li>
                  ))}
                </List>
              </>
            )}
            <SubHeading>Additional savings needed</SubHeading>
            <TooltipParagraph>
              {StyledCurrency(engineData.outOfPlanMonthlySavings * 12)} per year |{' '}
              {StyledCurrency(engineData.outOfPlanMonthlySavings)} per month
            </TooltipParagraph>
          </>
        )
      }
    }
  )
)

const PercentIncomeSeekToReplaceData = inject('store')(
  observer(
    class extends Component {
      render() {
        const { retirementIncomeGoalPct } = this.props.store.person
        const { modifiedCase } = this.props.store
        const engineType = modifiedCase ? 'modifiedCase' : 'recommendedCase'
        const engineData = this.props.store[engineType]

        return (
          <List>
            <li>
              {'Estimated current monthly household earnings* to be: '}
              {StyledCurrency(engineData.currentAfterTaxIncome)}{' '}
            </li>
            <li>
              Percent of income to replace:{' '}
              <Amount>{numberToDollars(retirementIncomeGoalPct, true)}</Amount>
              <Percent>%</Percent>
            </li>
            <li>Monthly spendable income: {StyledCurrency(engineData.targetedAftTaxIncome)}</li>
          </List>
        )
      }
    }
  )
)

const InvestmentMixTable = inject('store')(
  observer(
    class extends Component {
      render() {
        const { modifiedCase } = this.props.store
        const engineType = modifiedCase ? 'modifiedCase' : 'recommendedCase'
        const engineData = this.props.store[engineType]
        const { cCash, aCash } = engineData.investmentMixHelpData

        return (
          <StyledTable minWidth='360px'>
            <tbody>
              <tr>
                <TableHeader textAlign='right' padding='0 50px 0 0'>
                  Most conservative
                </TableHeader>
                <TableHeader padding='0 0 0 50px'>Most aggressive</TableHeader>
              </tr>
              <tr>
                <TableCell textAlign='right' padding='0 50px 0 0'>
                  {'Cash/Bonds: '} <Amount>{cCash}</Amount>
                  <Percent>%</Percent>
                </TableCell>
                <TableCell padding='0 0 0 50px'>
                  {'Cash/Bonds: '}
                  <Amount>{aCash}</Amount>
                  <Percent>%</Percent>
                </TableCell>
              </tr>
              <tr>
                <TableCell textAlign='right' padding='0 70px 0 0'>
                  {'Stock: '} <Amount>{100 - cCash}</Amount>
                  <Percent>%</Percent>
                </TableCell>
                <TableCell padding='0 0 0 70px'>
                  {'Stock: '}
                  <Amount>{100 - aCash}</Amount>
                  <Percent>%</Percent>
                </TableCell>
              </tr>
            </tbody>
          </StyledTable>
        )
      }
    }
  )
)

export default {
  // Help Icon
  // Onboarding
  otherEarnedIncome: () => (
    <TooltipText>
      <Heading>Other earned income</Heading>
      <TooltipParagraph>
        If you have taken a second job to supplement your income (or if you're already retired and
        have taken a part-time job), enter the annual pay you receive in this field.
      </TooltipParagraph>
      <TooltipParagraph>
        Note: unearned income from, for instance, a pension plan, alimony, or investment income from
        a rental property should not be included here. Income information from pension and
        investments is collected elsewhere. And while you are still saving for retirement, the
        GuidedChoice service seeks to replace only earned income after retiring since unearned
        income will continue after you've retired.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Onboarding
  socialSecurityIncome: () => (
    <TooltipText>
      <Heading>Social Security Income</Heading>
      <TooltipParagraph>
        When you earn a paycheck, generally, you pay taxes into the Social Security system, which
        appear as the FICA deduction. When you retire, you receive monthly benefits based on your
        earnings. These benefits have represented historically a significant portion of money
        available to individuals after retiring. But as you may or may not be aware, Social Security
        options and benefits have been diminishing and there is a lot of speculation about what we
        can expect in the future.
      </TooltipParagraph>
      <TooltipParagraph>
        While the probability of eliminating Social Security benefits altogether is highly unlikely,
        especially if you are under age 40, you might consider planning for your financial future
        without including Social Security. It doesn't mean you won't receive benefits, but if you
        plan without the expectation of receiving a certain amount, the benefits you do receive may
        be more supplementary than fundamental when you do retire eventually.
      </TooltipParagraph>
      <TooltipParagraph>
        No matter what choice you make here, you can always return to your profile information to
        change your answer.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Onboarding
  spouseFirstName: () => (
    <TooltipText>
      <Heading>Why are you asking for my spouse's first name only?</Heading>
      <TooltipParagraph>
        We don't need your spouse's full name to provide services to you. We use his or her name
        throughout our interface to further personalize your experience, rather than consistently
        referring to “your spouse.” (So if you want to use your favorite pet name, we'll never
        tell!)
      </TooltipParagraph>
    </TooltipText>
  ),
  // Onboarding
  valueAtRetirement: () => (
    <TooltipText>
      <Heading>Value at retirement</Heading>
      <TooltipParagraph>
        The dollar amounts displayed represent the projected value at the time of your retirement
        (as opposed to your spouse's retirement, if a spouse has been included in planning).
      </TooltipParagraph>
    </TooltipText>
  ),
  // Onboarding
  why80: () => (
    <TooltipText>
      <Heading>Why 80%</Heading>
      <TooltipParagraph>
        Accepted amongst most financial planners is the assertion that you'll need to plan to
        replace from 70% to 90% of your pre-retirement income. Your need may be greater than or
        lesser than that depending upon a variety of factors from lifestyle to health.
      </TooltipParagraph>
      <Heading>Will it be enough?</Heading>
      <TooltipParagraph>
        Additional considerations you may wish to take into account, such as paying off a mortgage
        and other debt, accounting for health insurance premiums and co-pays, and long-term or
        skilled nursing care can be found in Help.
      </TooltipParagraph>
      {/* <TooltipParagraph>
        And if you're feeling ambitious, in Tools you can also access our budget
        worksheet that may help you estimate what you'll need.
      </TooltipParagraph> */}
    </TooltipText>
  ),
  // Onboarding
  tellMeMoreHelp: () => (
    <TooltipText>
      <Heading>Tell me more</Heading>
      <TooltipParagraph>
        Expand this section for more information about how we calculated your spendable income goal
        of 80%.
      </TooltipParagraph>
      <Heading>Regarding your take-home pay</Heading>
      <TooltipParagraph>
        If your contribution rate has not been the same since the start of the year, our
        calculations of your take-home pay after your employer retirement plan contributions have
        been taken into account may appear off, which will affect our estimation of what 80% of your
        take-home pay is. Depending upon how much your contributions have varied throughout the
        year, this could have a larger or smaller impact on that calculation.
      </TooltipParagraph>
      <TooltipParagraph>
        The key is to focus on the amount of monthly spendable income we have determined. If that is
        too low, for instance, simply change your goal so that it is higher than 80% and see what
        that new monthly value is. Our service seeks to help you reach whatever goal you set.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Goals, Review Income Goal
  willReplacementGoalBeEnough: () => (
    <TooltipText>
      <Heading>Will it be enough?</Heading>
      <TooltipParagraph>
        Additional considerations you may wish to take into account, such as paying off a mortgage
        and other debt, accounting for health insurance premiums and co-pays, and long-term or
        skilled nursing care can be found in Help.
      </TooltipParagraph>
      {/* <TooltipParagraph>
        And if you're feeling ambitious, in Tools you can also access our budget
        worksheet that may help you estimate what you'll need.
      </TooltipParagraph> */}
    </TooltipText>
  ),
  whatIsAGoal: () => (
    <TooltipText>
      <Heading>What is a goal?</Heading>
      <TooltipParagraph>
        In this context, we mean an expense that you want to fund with money you are saving for
        retirement. If you can pay for your expense outside of retirement savings, do not add it
        here. Click Help for more information.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Goals, Amount Withdrawn
  hasAmountBeenWithdrawn: () => (
    <TooltipText>
      <Heading>Goal withdrawal</Heading>
      <TooltipParagraph>
        As long as the money needed for your goal has not yet been withdrawn, we will continue to
        assume you will be taking it out at some point this year. Once you've withdrawn the money
        and indicate that for us, we will no longer consider it a liability for which the
        projections need to account.
      </TooltipParagraph>
      <TooltipParagraph>
        Note: Previous year amounts are assumed to have been withdrawn. We no longer consider them
        in our projections.
      </TooltipParagraph>
      {/* <TooltipParagraph>
        And if you're feeling ambitious, in Tools you can also access our budget
        worksheet that may help you estimate what you'll need.
      </TooltipParagraph> */}
    </TooltipText>
  ),
  // Overall Strategy, Module 1, 6
  aboutYourResults: currentTotalBalance =>
    currentTotalBalance === 0 ? (
      <TooltipText>
        <Heading>Current balance equals future projected balance</Heading>
        <TooltipParagraph>
          Quite simply, this means that GuidedChoice does not have any investment accounts on record
          for which to project growth.
        </TooltipParagraph>
      </TooltipText>
    ) : (
      <TooltipText>
        <Heading>Current balance equals future projected balance</Heading>
        <TooltipParagraph>
          Projections are all about time. If you've already reached the retirement age GuidedChoice
          has on record, there's no time to which growth can be projected.
        </TooltipParagraph>
      </TooltipText>
    ),
  replacementIncome: () => (
    <TooltipText>
      <Heading>Projected replacement income</Heading>
      <TooltipParagraph>
        GuidedChoice projects how your investments may perform until the year of your retirement and
        combines that with any income from sources such as Social Security benefits, pension plan
        payouts, and annuity payouts that have been included for consideration, to determine how
        much money you have accumulated to pay for the period in your life during which you are no
        longer earning a paycheck, “retirement.” Then, using standard life expectancy tables—plus
        ten years—we determine the amount of income you may expect each month in an average market
        (probability: 2 out of 3) and compare it against your current income to come up with the
        percentage that may be replaced after retiring.
      </TooltipParagraph>
    </TooltipText>
  ),
  balanceHidden: () => (
    <TooltipText>
      <Heading>Current balance hidden</Heading>
      <TooltipParagraph>
        We've hidden your current balance on this chart because it is more than what the projected
        value will be in the future. So although you are investing your current balance and
        intuitively you expect a future balance to be higher, what's happening is either there are
        inefficiencies in your current portfolio causing an issue or the money that you are saving
        for your future is going towards funding other goals vs. funding retirement. If you want to
        fund more of your retirement and you are funding other goals, you'll need to adjust the
        annual amount(s) required.
      </TooltipParagraph>
    </TooltipText>
  ),
  investmentIncomeVSLifetimeIncome: () => (
    <TooltipText>
      <Heading>Investment income vs. lifetime income</Heading>
      <TooltipParagraph>
        <b>Investment income</b> is your projected monthly income from your investment balance.
      </TooltipParagraph>
      <TooltipParagraph>
        <b>Lifetime income</b> is steadier income that comes from sources such as Social Security,
        pensions, and annuities.
      </TooltipParagraph>
      <InnerHeading>Why is my lifetime income a range rather than a single amount?</InnerHeading>
      <TooltipParagraph>The range accounts for inflation and taxes.</TooltipParagraph>
      <TooltipParagraph>
        Income that we think of as “guaranteed” is subject to the effects of inflation. Any income
        from Social Security usually has the protective Cost of Living Adjustment (COLA) used to
        keep up with inflation as measured by the Consumer Price Index (CPI). Income from pensions
        and annuities, more often than not, do not include COLA adjustments.
      </TooltipParagraph>
      <TooltipParagraph>
        In other words, if you receive a $2,500 monthly check for the rest of your life, next year
        that $2,500 may be worth only $2,489 in terms of purchasing power…and the next year only
        $2,481, and so on. GuidedChoice will show what it may be worth, depending upon inflation.
      </TooltipParagraph>
    </TooltipText>
  ),
  forLegacy: () => (
    <TooltipText>
      <Heading>For legacy</Heading>
      <TooltipParagraph>
        The money left for a legacy is a range of what could remain depending upon how the market
        performs over the course of your retirement.
      </TooltipParagraph>
    </TooltipText>
  ),
  standardDeviation: () => (
    <TooltipText>
      <Heading>What do you mean by standard deviation?</Heading>
      <TooltipParagraph>
        Say you have a bunch of results from running simulations. You can determine the average
        result easily enough, but that doesn't really tell you how much risk your investment mix is
        exposed to. That can be determined by the standard deviation—how spread out those results
        are from the average result. A low standard deviation means that most of the results come
        close to the average and therefore the risk being taken is lower; while a higher standard
        deviation means that the results are more spread out, meaning greater expected volatility
        (risk) of the investment mix.
      </TooltipParagraph>
      <Heading>Can I change the risk level GuidedChoice recommends?</Heading>
      <TooltipParagraph>
        It's important to understand that GuidedChoice recommends an optimal portfolio with the goal
        of minimizing the impact of market ups and downs over your projected lifetime.
      </TooltipParagraph>
      <TooltipParagraph>
        Changes to inputs, such as how your accounts are invested currently, can result in
        GuidedChoice revising our recommended portfolio. But that means you'll need to make changes
        to the risk you're taking in your investment accounts first. Then when you return to
        GuidedChoice, we'll take those new investment allocations into consideration when creating
        your optimal portfolio.
      </TooltipParagraph>
    </TooltipText>
  ),
  lifeExpectancy: () => (
    <TooltipText>
      <Heading>Life expectancy</Heading>
      <TooltipParagraph>
        GuidedChoice plans for 10 years beyond the life expectancy age to help prevent running out
        of money.
      </TooltipParagraph>
      <TooltipParagraph>
        If you are looking at the page titled “Understanding your results,” notice how the graph
        ends the year of (latest) life expectancy. Similarly, expand the income details section to
        see the year-by-year table; the last age will be 10 years beyond the (latest) life
        expectancy.
      </TooltipParagraph>
    </TooltipText>
  ),
  lifeExpectancyMortalityAge: () => (
    <TooltipText>
      <Heading>Life expectancy</Heading>
      <TooltipParagraph>
        GuidedChoice uses standard mortality tables to determine your life expectancy and then adds
        ten years to help ensure you won't run out of money. Even if you adjust your life
        expectancy, from a planning perspective, we'll still add ten years.
      </TooltipParagraph>
      <TooltipParagraph>
        It gets a little more complicated if you're married and including your spouse in planning.
        In this case, we'll add ten years to the individual expected to live further into the
        future.
      </TooltipParagraph>
      <TooltipParagraph>
        Let's look at an example.
        <br />
        You: born in 1965 and according to standard mortality tables, your life expectancy is 81.
        <br />
        Spouse: born in 1969 and according to standard mortality tables has a life expectancy of 78.
      </TooltipParagraph>
      <TooltipParagraph>
        1965 + 81 years = 2046
        <br />
        1969 + 78 years = 2047
      </TooltipParagraph>
      <TooltipParagraph>
        Since your spouse is projected to live till 2047, we'll add ten years to your spouse's life
        expectancy, making it 88. Your life expectancy remains at 81.
      </TooltipParagraph>
    </TooltipText>
  ),
  incomeDetails: () => (
    <TooltipText>
      <Heading>Income details table</Heading>
      <TooltipParagraph>
        The <b>total available to spend</b> at each age listed is made up of:
      </TooltipParagraph>
      <TooltipParagraph>
        <b>Lifetime income</b>, which is the amount you can expect from steadier income sources,
        such as Social Security, pensions, and annuities…
      </TooltipParagraph>
      <TooltipParagraph>
        And <b>withdrawals from investment balance</b>, which is the variable income you'll withdraw
        to make up the difference between that year's lifetime income and the total available to
        spend.
      </TooltipParagraph>
      <InnerHeading>If you see a negative number…</InnerHeading>
      <TooltipParagraph>
        Don't be alarmed. A negative amount is a good thing; it means that more money is available
        to you that year than what is provided by the amounts from lifetime income and investment
        income sources. We assume you keep the difference invested so that it is available for
        future withdrawals.
      </TooltipParagraph>
    </TooltipText>
  ),
  thingsYouMaySeeInChart: () => (
    <TooltipText>
      <InnerHeading>Here's some things you may see in the chart</InnerHeading>
      <Spacer space='10px' />
      <HeadingWithoutPadding>A steep increase in income in the early years</HeadingWithoutPadding>
      <TooltipParagraph
        css={css`
          margin-top: 0;
        `}>
        Almost exclusively, this means that one spouse is still working.
      </TooltipParagraph>
      <HeadingWithoutPadding>A steep drop in income in the early years</HeadingWithoutPadding>
      <TooltipParagraph
        css={css`
          margin-top: 0;
        `}>
        Usually this drop happens when income from an employer ceases. The drop will be steeper if
        some lifetime income amounts have not yet begun.
      </TooltipParagraph>
      <HeadingWithoutPadding>A steep drop in income in the later years</HeadingWithoutPadding>
      <TooltipParagraph
        css={css`
          margin-top: 0;
        `}>
        Usually this drop is due to the death of a spouse, since lifetime income amounts may stop or
        be reduced for the remaining spouse. However, it could also be that your savings has run out
        and all that's left is lifetime income.
      </TooltipParagraph>
      <HeadingWithoutPadding>A decrease in lifetime income</HeadingWithoutPadding>
      <TooltipParagraph
        css={css`
          margin-top: 0;
        `}>
        Social Security benefits usually, but not always, keep up with inflation, as measured by the
        Consumer Price Index (CPI). Pension and annuity benefits, more often than not do not keep up
        with inflation, unless you add that benefit.
      </TooltipParagraph>
      <TooltipParagraph
        css={css`
          margin-top: 0;
        `}>
        GuidedChoice takes inflation into account. So if any of these retirement benefits do not
        keep up with inflation, the real value of monthly income from those plans actually goes
        down—you may receive a $2,500 monthly check for the rest of your life, but that $2,500 may
        not be worth the same tomorrow as it is today. We help to show what it may be worth in the
        future.
      </TooltipParagraph>
    </TooltipText>
  ),
  understandingYourResults: () => (
    <TooltipText>
      <Heading>Tell me more about understanding my results</Heading>
      <TooltipParagraph>
        The money you have in retirement can be classified in terms of steady lifetime income vs.
        investment income. And most would agree that income you can count on brings a certain peace
        of mind. Our advice seeks to use your investment income to supplement your steady income so
        that you have a total amount on average that you can count on, even with market
        fluctuations. Bottom line is, you want the total average income over the course of
        retirement to be a straight line across the years vs. one that goes up and down, leaving you
        with uncertainty of what you can expect to spend from year to year.
      </TooltipParagraph>
      <Heading>How likely are my results</Heading>
      <TooltipParagraph>
        <b>87%.</b> That's a high chance of achieving these results. But keep in mind, markets move
        up and down, and no one can say for certain when changes will happen. In addition, tax laws
        and your personal situation change as well. It's important that you return each year to get
        the benefit of new tax rules, market updates, and any changes in your personal
        circumstances.
      </TooltipParagraph>
    </TooltipText>
  ),
  currentBalance: () => (
    <TooltipText>
      <Heading>Current balance—what's included?</Heading>
      <TooltipParagraph>
        This chart is designed to show you how the total of your investments can grow over time.
        What's not included is income from sources such as Social Security, a monthly pension,
        annuity payouts, etc. However, these income sources, if any apply to you, are considered in
        the monthly/annual income projections.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Overall Strategy, Module 2
  projectedIncomeRange: () => (
    <TooltipText>
      <Heading>Projected income range</Heading>
      <TooltipParagraph>
        Intuitively, you understand that there is no way for us to know the exact market conditions
        when you retire. But using powerful simulation technology and robust analytics, GuidedChoice
        projects hundreds of possible market scenarios and then determines their impact on your
        projected retirement income. We then measure the likelihood that any one outcome would
        occur.
      </TooltipParagraph>
      <TooltipParagraph>
        The probability of an outcome is what we expect the frequency of any one outcome to be after
        running several hundred different scenarios.
      </TooltipParagraph>
      <TooltipParagraph>
        On the low end of the range, we display a “weak” market outcome. We think it's unlikely your
        result will be lower than this amount. We estimate your chances are 95 out of 100 that
        you'll have at least this much monthly income after retiring.
      </TooltipParagraph>
      <TooltipParagraph>
        On the high end, we display a “strong” market outcome. Don't plan on more than this amount
        in retirement. We estimate you have an equal chance of having more or less than this much
        monthly income after retiring.
      </TooltipParagraph>
      <TooltipParagraph>
        The single number displayed above is the “average” market outcome. We think you should focus
        on this amount. We estimate your chances are 2 out of 3 that you'll have at least this much
        monthly income after retiring.
      </TooltipParagraph>
    </TooltipText>
  ),
  investmentRisk: () => (
    <TooltipText>
      <Heading>Investment Risk</Heading>
      <TooltipParagraph>
        All investments have an expected rate of return based on estimates and historical averages.
        Risk measures the likelihood that your investment will do better or worse than the expected
        rate. Although most people focus on "down-side" risk—how much they could lose—there is such
        a thing as "up-side" risk, which is the chance that the investment return will be much
        greater than expected. The greater the difference between these two points, the greater the
        volatility. This is also referred to as a "risk/return" trade-off.
      </TooltipParagraph>
      <TooltipParagraph>
        Based on your goals, we calculate a “preferred range” for risk. Risk is one of the variables
        you can control in the GuidedChoice service, thus giving you an opportunity to assess the
        impact of investing at different levels of risk. Investments considered to be higher risk
        are generally more likely to experience higher volatility in the short-term. You can expect
        your account balance to vary significantly with higher risk. However, these investments have
        had greater returns over the long term historically. If an investor has a very short
        investment time horizon, it is usually recommended that they invest in a less risky or less
        volatile portfolio.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Overall Strategy, Module 6
  whatYouCanExpect: () => (
    <TooltipText>
      <Heading>What you can expect</Heading>
      <TooltipParagraph>
        GuidedChoice projects the value of your current balance into the future to show how your
        current balance can grow. (If you're planning with a spouse, the value is based on your
        retirement age.)
      </TooltipParagraph>
      <TooltipParagraph>
        Our projections assume that you will follow our advice, including changing the investment
        mix for those accounts included for additional investment advice.
      </TooltipParagraph>
    </TooltipText>
  ),
  whatYouSaved: () => (
    <TooltipText>
      <Heading>What you've saved</Heading>
      <TooltipParagraph>
        This is the balance of all the investment accounts that you've told us about. Money from
        sources such as Social Security, pension plans, and/or annuities is not included as payout
        arrangements for these kinds of accounts are very different from an investment account over
        which you have control of how your contributions into the account are invested.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Overall Strategy, Compare scenarios drawer
  restoreRecommendation: () => (
    <TooltipText>
      <Heading>Restore GuidedChoice recommendation</Heading>
      <TooltipParagraph>
        Selecting this button will re-set all the variables below to what GuidedChoice recommends
        you do to meet your goals.
      </TooltipParagraph>
      <TooltipParagraph>
        If all you want to restore is the risk level we recommended, there is a button in the Risk
        section where you can do that.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Overall Strategy, Compare scenarios drawer
  scenarioNotListed: () => (
    <TooltipText>
      <Heading>Saved scenario not listed</Heading>
      <TooltipParagraph>
        GuidedChoice keeps up to five saved scenarios for seven days only. If a previously saved
        scenario is not listed, it may be more than seven days or it may have been written over by a
        later saved scenario.
      </TooltipParagraph>
      <TooltipParagraph>
        The intent behind saving and then comparing scenarios is to allow you to see the difference
        in projected retirement income from, for instance, including an additional financial goal
        vs. not, retiring earlier or later, taking more or less risk, etc.
      </TooltipParagraph>
      <TooltipParagraph>
        As well, we do not encourage acting upon a saved scenario's results alone, but instead ask
        that you make the changes once again to receive updated results. There are a multitude of
        changes that could have taken place between now and when the scenario was saved that would
        return very different results.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Overall Strategy
  incomeGoalNotMet: () => (
    <TooltipText>
      <Heading>Income goal not met</Heading>
      <TooltipParagraph>
        We've run hundreds of scenarios to find a strategy that will meet your retirement income
        goal (and any other financial goals you entered) most of the time, but we could not. The
        replacement percentage shown in the bubble is the closest we could come while keeping your
        income during retirement stable.
      </TooltipParagraph>
      <TooltipParagraph>
        We suggest taking advantage of our scenario planning functionality through the Make Changes
        button below. Depending upon how far from your goal you are, you could find simply planning
        to work another couple years makes all the difference.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Overall Strategy, What's changed
  whatsChanged: () => (
    <TooltipText>
      <Heading>What's changed?</Heading>
      <TooltipParagraph>
        For a side-by-side comparison of two scenarios, e.g. what you're currently doing vs. what we
        recommend, select the Compare Scenarios button at the bottom of the page.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Compare Drawer
  whyCantImplement: () => (
    <TooltipText>
      <Heading>Why can't I implement my saved scenario from this page?</Heading>
      <TooltipParagraph>
        While at first it may seem like desirable functionality to be able to click a button to
        implement your saved strategy, the drawback is that part of your satisfaction with a
        scenario is very likely connected to the projected results. Your projections are not based
        solely on the performance of the investments you're holding, but on a myriad of other
        factors that may have changed since your scenario was run, including but not limited to:
        additional financial goals, changes to financial goals, and accounts being added and/or
        deleted. Advice is specific to what we know about you and your investments at a particular
        point in time and since we can't plan on how abruptly or subtly changes will take place, it
        is wiser to re-run results and decide then whether to accept or make other adjustments.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Accounts dashboard
  employerRelatedAccountsBalance: () => (
    <TooltipText>
      <Heading>Employer-related accounts balance</Heading>
      <TooltipParagraph>
        The total displayed is the balance of retirement investment accounts tied to a current or
        former employer. Account types include: 401(k). 403(b), 401(a), 457, and Keogh.
      </TooltipParagraph>
      <TooltipParagraph>
        Pension plans are not included in the balance, though if added, is taken into consideration
        when determining projected income.
      </TooltipParagraph>
      <TooltipParagraph>
        Amounts to include from SEP IRAs, SIMPLE IRAs, and Payroll Deduct IRAs will be available in
        a future version of our service.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Accounts dashboard
  otherRelatedAccountsBalance: () => (
    <TooltipText>
      <Heading>Other types of accounts balance</Heading>
      <TooltipParagraph>
        The total displayed is the balance of investment accounts outside of any arrangements with a
        current or former employer, earmarked for expenses after retiring. Accounts include IRAs
        (Rollover, Roth, deductible, non-deductible), brokerage accounts, fixed annuities, and other
        assets.
      </TooltipParagraph>
      <TooltipParagraph>
        Amounts to include from Health Savings Account and education accounts such as 529 plans,
        UGMAs, and UTMAs will be available in a future version of our service.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Add account/ investments
  otherInvestmentOptions: () => (
    <TooltipText>
      <Heading>Other available investment options</Heading>
      <TooltipParagraph>
        We get it. Adding other available options can be burdensome. So while it is recommended you
        provide this information, it is not required. The caveat, however, is that when we recommend
        an investment mix for you later in the service, we won't be able to name the investment
        option but will tell you simply the type of fund or investment you should look for amongst
        your available options, e.g. Your Plan's Short Term Bond Fund, Your Plan's International
        Fund, etc.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Add account/ annuity
  annuityDistribution: () => (
    <TooltipText>
      <Heading>Annuity distribution types—the basics</Heading>
      <TooltipParagraph
        css={css`
          margin: 0;
        `}>
        Not sure of the distribution type for an annuity you already own? Here's a brief description
        of the different types we take into account.
      </TooltipParagraph>
      <Heading>Single Life</Heading>
      <TooltipParagraph
        css={css`
          margin: 0;
        `}>
        Annuity payment to account owner begins at a specified age and ends upon account owner's
        passing. There is no beneficiary payment.
      </TooltipParagraph>
      <Heading>Joint and Survivor</Heading>
      <TooltipParagraph
        css={css`
          margin: 0;
        `}>
        Annuity payment to account owner begins at a specified age; it continues at a certain dollar
        amount while the account owner remains living. Upon account owner's passing, annuity
        payments continue to be paid to the survivor until their death, the amount of which is
        determined by the survivor percentage.
      </TooltipParagraph>
      <Heading>Joint Life</Heading>
      <TooltipParagraph
        css={css`
          margin: 0;
        `}>
        Annuity payment to account owner begins at a specified age and continues at the same payment
        level through the lives of both the account owner and spouse.
      </TooltipParagraph>
      <Heading>Life with Period Certain</Heading>
      <TooltipParagraph
        css={css`
          margin: 0;
        `}>
        Annuity payments are guaranteed through a certain period of time, e.g., 10 years, whether
        the account owner remains alive or passes during that time. After that certain period has
        ended, provided the account owner is still living, payments continue through the account
        owner's life.
      </TooltipParagraph>
      <Heading>Period Certain</Heading>
      <TooltipParagraph
        css={css`
          margin: 0;
        `}>
        Annuity payments are guaranteed through a certain period of time, e.g., 10 years, whether
        the account owner remains alive or passes during that time. After that certain period has
        ended, annuity payments end.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Add account/ annuity
  annuityInflation: () => (
    <TooltipText>
      <Heading>Possible inflation protection features</Heading>
      <TooltipParagraph
        css={css`
          margin: 0;
        `}>
        While most annuities won't guarantee a real rate of return at or above inflation or apply
        COLA (Cost of Living Adjustment), some will have a provision where the annuity automatically
        increases at a fixed percent each year, regardless of inflation or cost of living. It's this
        fixed percentage we are asking for. If you are uncertain, it's better to leave at 0% till
        you're sure.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Add account/ annuity
  costOfAnnuity: () => (
    <TooltipText>
      <Heading>Cost of annuity</Heading>
      <TooltipParagraph
        css={css`
          margin: 0;
        `}>
        If you do not recall how much you paid for your annuity, you can leave it as $0; however,
        this means that we will assume the entire monthly payment you receive is taxable. This means
        that we will overestimate the taxes on your monthly payments once you begin receiving them.
        This is the more conservative route, as it means you would likely have more money to spend
        rather than less.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Add account
  includeInvestmentAdvice: () => (
    <TooltipText>
      <Heading>Including investment advice</Heading>
      <TooltipParagraph>
        When you elect to have GuidedChoice include investment advice on an account, it is important
        that you are willing to make investment changes to the account. In addition, if you have
        investments you are unwilling to sell, it is best to exclude the account from advice. The
        projections of future income assume that you will make changes to the investment mix as
        recommended to any accounts included for advice.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Add account, paycheck contributions
  eligibleContributions: includeCantChange => (
    <TooltipText>
      <Heading>Eligibility to make contributions to the account</Heading>
      <TooltipParagraph>
        Let us know here whether or not contributions can be made to this account (not whether they
        are being made currently). This information is important with respect to our advice.
      </TooltipParagraph>
      {includeCantChange && (
        <div>
          <Heading>Can't change answer?</Heading>
          <TooltipParagraph>
            In general, an individual is eligible to contribute to one employer-related plan account
            at a time only. The exception is when the plan is a 403(b), 401(a), or pension. In these
            cases, the individual is eligible to contribute to a 457 as well.
          </TooltipParagraph>
          <TooltipParagraph>
            If you still think you are eligible to contribute to the account you're trying to add or
            edit, you may need to change the eligibility answer on another account first, then
            return to this one.
          </TooltipParagraph>
          <TooltipParagraph>
            Note: For the foreseeable future, the ability for an individual to be eligible to
            contribute to more than one employer-related plan account at a time will not be
            available on spousal accounts.
          </TooltipParagraph>
        </div>
      )}
    </TooltipText>
  ),
  // Paycheck Contributions
  currentlyContributing: () => (
    <TooltipText>
      <Heading>Currently contributing</Heading>
      <TooltipParagraph>
        Let us know here whether or not contributions are being made to this account currently. This
        information is important with respect to the future income projections. If you have
        temporarily stopped contributions, let us know when you resume contributing.
      </TooltipParagraph>
      <TooltipParagraph>Paying back a loan?</TooltipParagraph>
      <TooltipParagraph>
        At this time GuidedChoice does not receive information distinguishing between loan repayment
        contributions and regular contributions. It is an important distinction in that if you are
        making repayments, the rate allocated to the repayment may not continue until retirement but
        end sooner. If included through to retirement, we will overstate the future income.
      </TooltipParagraph>
      <List>
        <li>
          If you are making loan repayments AND regular contributions, OR if you are making regular
          contributions only (with no loan repayments), indicate yes and let us know the regular
          contribution amount further below.
        </li>
        <li>
          If you are making loan repayments only, indicate no and no contribution amount below.
        </li>
      </List>
    </TooltipText>
  ),
  // Add account, Paycheck Contributions
  contributionType: () => (
    <TooltipText>
      <Heading>Form of contributions</Heading>
      <TooltipParagraph>
        Your plan's rules may allow you to contribute as a percentage of pay or as a fixed dollar
        amount. As a percentage of pay, there can be times when the contribution amount differs from
        paycheck to paycheck. This is most commonly seen with employees paid hourly.
      </TooltipParagraph>
      <TooltipParagraph>
        As a fixed dollar amount, the contribution made remains the same from paycheck to paycheck.
      </TooltipParagraph>
      <TooltipParagraph>
        Note: If you are unable to change the form contributions take, it most likely stems from one
        of the following circumstances:
      </TooltipParagraph>
      <List>
        <li>
          Information regarding this account was provided to GuidedChoice by your plan account's
          recordkeeper; consequently, only certain pieces of information can be modified.
        </li>
        <li>
          Or, because you participate in a particular type of employer-related plan (a 401(a),
          403(b), or pension), you are eligible to participate in a 457 as well. These two accounts
          are “connected” in the GuidedChoice database, such that the form contributions take in the
          first plan must be the same in the second.
        </li>
      </List>
    </TooltipText>
  ),
  // Add account, Paycheck Contributions
  rothContributions: () => (
    <TooltipText>
      <Heading>What are Roth contributions?</Heading>
      <TooltipParagraph>
        If Roth contributions are made available by the employer, income taxes are paid on what's
        contributed the year the contribution is made, but taxes won't be taken when money is
        withdrawn—neither the contributions or the associated earnings.
      </TooltipParagraph>
      {/* <TooltipParagraph>
        There are considerations regarding different types of contributions. To
        learn more about them, please access Help in the upper right corner.
      </TooltipParagraph> */}
    </TooltipText>
  ),
  // Add account, Contribution limits for employer-related plans (e.g. 401(k), 403(b), 457)
  contributionLimits: () => <EmployerContributionLimits />,
  // Add account, Paycheck Contributions
  afterTaxContributions: () => (
    <TooltipText>
      <Heading>What are after-tax contributions?</Heading>
      <TooltipParagraph>
        While most employer-sponsored retirement plans do not offer an after-tax option, if this
        option is made available, income taxes are paid on what's contributed the year the
        contribution is made, and upon withdrawing money from this account, the investment earnings
        are as well.
      </TooltipParagraph>
      {/* <TooltipParagraph>
        There are considerations regarding different types of contributions. To
        learn more about them, please access Help in the upper right corner.
      </TooltipParagraph> */}
    </TooltipText>
  ),
  // Add account, Employer Contributions
  employerMatch: () => (
    <TooltipText>
      <Heading>Employer match</Heading>
      <TooltipParagraph>
        Money contributed to some types of employer-sponsored retirement plan accounts may be
        partially matched by the employer based on an individual's contribution. For example, an
        employer might match 50 cents for every dollar you contribute. This matching may be capped
        at a certain percentage, such as 5% of your salary.
      </TooltipParagraph>
      <TooltipParagraph>
        If available, contribute at a <Italics>minimum</Italics> what is required to receive the
        maximum match. This is, essentially, free money—don't pass it by!
      </TooltipParagraph>
    </TooltipText>
  ),
  // Add account, Employer Contributions
  profitSharing: () => (
    <TooltipText>
      <Heading>Fixed employer contribution</Heading>
      <TooltipParagraph>
        Employers, such as the government, may contribute a fixed amount to your account. It differs
        from a match in that the amount contributed by the employer is not affected by your
        contribution.
      </TooltipParagraph>
      <Heading>Profit sharing</Heading>
      <TooltipParagraph>
        Some employers include a program in which they share a portion of profits with eligible
        employees. It is a percentage of pay, can be given in the form of stocks, bonds, or cash,
        and can be deferred until retirement.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Add account, Paycheck Contributions
  whyCommitmentMatters: () => (
    <TooltipText>
      <Heading>Commitment to ongoing contributions</Heading>
      <TooltipParagraph>
        First, we applaud the commitment to ongoing annual contributions. Truly, your future self
        will be happy for the sacrifices made.
      </TooltipParagraph>
      <TooltipParagraph>
        What we want to impress upon you, however, is that when you enter an amount here, the{' '}
        <b>
          future income projections assume the amount entered will be contributed{' '}
          <i>every year up to and including the year of your retirement.</i>
        </b>{' '}
        (If this is your spouse's account, we assume ongoing contributions up to and including the
        year of your spouse's retirement.) If you change the amount, stop contributing, or no longer
        intend to make contributions annually, it is important to return to the advisory service to
        update this information and receive new results.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Add account, IRA
  iraContributionLimit: () => <IraContributionLimits />,
  // Add account, Pension
  multiplePayoutOptions: () => (
    <TooltipText>
      <Heading>Multiple payout options</Heading>
      <TooltipParagraph>
        If your pension plan statement lists varying lump sum payment amounts at different ages, use
        the age and amount closest to your stated retirement age.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Add account, Pension
  whyLumpSumMatters: () => (
    <TooltipText>
      <Heading>Receipt of lump sum payout</Heading>
      <TooltipParagraph>
        We realize you may be a long way from receiving a pension payout—and from retiring. But the
        reason we ask is because when we develop a retirement income withdrawal strategy, the lump
        sum payment will be considered either expected future income or money you've received that
        is now available as retirement income.
      </TooltipParagraph>
      <TooltipParagraph>
        Please note: we assume a lump sum payment will be rolled into a tax-advantaged account, such
        as an IRA, upon receipt so that it can be a source of income after retiring. If, however, it
        is spent on something else, it means it's no longer available for retirement income so it
        should be deleted from the retirement assets listed.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Add Investments
  investmentTypes: () => (
    <TooltipText>
      <Heading>Investment Types</Heading>
      <SubHeading>Stock</SubHeading>
      <TooltipParagraph>
        When you own stock, that means you have partial ownership of the company. Stock is
        considered the riskiest type of investment, though there are varying levels of risk.
      </TooltipParagraph>
      <SubHeading>Mutual funds</SubHeading>
      <TooltipParagraph>
        Mutual funds are made up of some combination of stocks, bonds, commodities, and/or cash.
        Investing in mutual funds is a good way to mitigate the risk of holding individual stocks,
        though again, there are varying levels of risk associated with mutual funds.
      </TooltipParagraph>
      <SubHeading>ETFs</SubHeading>
      <TooltipParagraph>
        Also known as Exchange Traded Funds, these mutual funds have traditionally used index funds,
        which are designed around specific characteristics or factors.
      </TooltipParagraph>
      <SubHeading>Bonds</SubHeading>
      <TooltipParagraph>
        A bond is debt, a loan made by investors to companies and governmental entities that promise
        to pay back the load at a specific interest rate.
      </TooltipParagraph>
      <SubHeading>Cash</SubHeading>
      <TooltipParagraph>
        In an investment portfolio, cash means highly liquid, safe investments that can be easily
        changed into cash. These include Treasury bills (a.k.a. T-bills), money market accounts,
        certificates of deposit (CDs), or even short-term bond funds.
      </TooltipParagraph>
      <SubHeading>Other</SubHeading>
      <TooltipParagraph>
        Investments that don't fit into the other categories listed. Choose a proxy—a similar
        investment—to include your particular investment.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Add investment
  findInvestment: () => (
    <TooltipText>
      <Heading>Finding account investments</Heading>
      <TooltipParagraph>
        How you find your investment will differ according to the type of investment being added.
      </TooltipParagraph>
      <SubHeading>Stock, Mutual Fund, ETF</SubHeading>
      <TooltipParagraph>
        Enter either the ticker symbol or the name. All publicly traded investments should be
        available to add; if you cannot find the name of your investment, it may not be publicly
        traded, or it may be a special investment, such as an institutional fund, an investment
        wrap, or even stock in a privately held company. If part of an employer-related retirement
        plan account, the plan's materials should tell you more information about the investment so
        that you may add a proxy:
      </TooltipParagraph>
      <List>
        <li>
          Return to investment type and select “Other”{' '}
          <List>
            <li>Be sure to review the information below when making this selection</li>
          </List>
        </li>
        <li>Choose from the list provided</li>
      </List>
      <SubHeading>Bond, Cash, Other</SubHeading>
      <TooltipParagraph>
        Select from a list of options available. If you select “Other,” be sure to review the
        information below when making this selection.
      </TooltipParagraph>
      <SubHeading>If you select “Other”…</SubHeading>
      <TooltipParagraph>
        Trade-off: If you do not know which name most closely represents the investment, you may
        wish to choose money market, which is a cash equivalent. Risk will most probably be
        understated, and returns may be under or overstated. Depending on the type of asset, this
        could have a significant impact to the quality of the advice.
      </TooltipParagraph>
      <TooltipParagraph>
        Additionally, if from this list you select real estate, precious metals, collectibles, or
        other, we will not have market benchmarks. For instance, an apartment building in Beverly
        Hills may have significantly different risk and return characteristics than an apartment
        building in downtown Los Angeles. Currently, GuidedChoice treats the investment like cash;
        considering the trade-off stated above, in these cases, you may decide to plan without
        considering this investment.
      </TooltipParagraph>
    </TooltipText>
  ),
  // Add investments, Value
  investmentValue: () => (
    <TooltipText>
      <Heading>Investment Value</Heading>
      <SubHeading>Add balance</SubHeading>
      <TooltipParagraph>
        If you have a balance, select this button, then add the information requested.
      </TooltipParagraph>
      <TooltipParagraph>
        Investments purchased with after-tax money will request the cost basis, which is the amount
        you paid when you originally purchased the investment. This information is usually available
        on the statement you receive from your investment provider. For instance, if you invest in a
        mutual fund at $35 per share, that is your cost basis, whether it is worth $40 or $30 per
        share currently.
      </TooltipParagraph>
      <TooltipParagraph>
        Be aware: If you are investing regularly, such as when you are part of an employer
        retirement plan, you will have a different cost basis for each purchase period, depending on
        the price of the investment at the time of purchase.
      </TooltipParagraph>
      <TooltipParagraph>
        GuidedChoice uses the amount you invested to determine future tax liability. If you do not
        know your cost basis, simply leave it as $0 and we will consider the entire balance as
        taxable upon withdrawal, which may understate your projected income.
      </TooltipParagraph>
      <SubHeading>Not Invested</SubHeading>
      <TooltipParagraph>
        For certain types of plans, we request that you enter investment options, even if you are
        not investing in them currently. We do this in order to be able to give you more specific
        investment advice for the account. If the investments you hold currently do not include all
        the asset classes our advice incorporates for you, we will list a generic name, such as
        “Your Plan's International Stock Fund,” and you will need to determine which investment
        option that is.
      </TooltipParagraph>
    </TooltipText>
  ),

  // Institutional After-tax tooltip
  costBasis: () => (
    <TooltipText>
      <Heading>Cost basis</Heading>
      <TooltipParagraph>
        Cost basis refers to the amount you have contributed towards the purchase of an investment.
        If you don't know the amount you have contributed, leave as $0. GuidedChoice will treat the
        total balance as taxable when you take a withdrawal. This approach is more conservative and
        will probably understate your actual monthly income at retirement.
      </TooltipParagraph>
    </TooltipText>
  ),

  // Personal Help Icon
  ongoingMonthlyContributions: contributionList => (
    <TooltipText>
      <Heading>Ongoing monthly contributions by account</Heading>
      {contributionList.map(account => (
        <MonthlyContributionRow key={account.accountName + account.accountId}>
          <div>
            <DollarSign>$</DollarSign>
            <Amount>{numberToDollars(account.annualContributionDollars / 12, true)}</Amount>
          </div>
          <MonthlyContributionName>{account.accountName}</MonthlyContributionName>
        </MonthlyContributionRow>
      ))}
    </TooltipText>
  ),

  ongoingMonthlyContributionsInitialAdvice: displayInflation => (
    <TooltipText>
      <Heading>Projected range of monthly income after retiring</Heading>
      <ProjectedIncomeComparisonTableInitialAdvice displayInflation={displayInflation} />
      <Spacer space='4px' />
      <TooltipParagraph>
        <Bold>Ask yourself:</Bold> Am I willing to make the changes GuidedChoice recommends to
        realize the difference in outcomes from what I'm doing today?
      </TooltipParagraph>
      <SubHeading>Weak, average, and strong markets?</SubHeading>
      <TooltipParagraph>
        No one knows what market conditions will be like when you retire. But for simplicity, we've
        focused on the average market outcome, while providing upper and lower outcomes for stronger
        and weaker market conditions. And while it may be tempting to focus on the strong market
        outcome, conservatively speaking, if you can cover your needs with the weak market outcome,
        you know you're in great shape.
      </TooltipParagraph>
      <List>
        <li>
          <Bold>Weak market</Bold>: We think it's unlikely your result will be lower than this
          amount. We estimate your chances are 95 out of 100 that you'll have at least this much
          monthly income after retiring.
        </li>
        <li>
          <Bold>Average market</Bold>: We think you should focus on this amount. We estimate your
          chances are 2 out of 3 that you'll have at least this much monthly income after retiring.
        </li>
        <li>
          <Bold>Strong market</Bold>: Don't plan on more than this amount in retirement. We estimate
          you have an equal chance of having more or less than this much monthly income after
          retirement.
        </li>
      </List>
    </TooltipText>
  ),
  ongoingMonthlyContributionsModifiedAdvice: displayInflation => (
    <TooltipText>
      <Heading>Projected range of monthly income after retiring</Heading>
      <ProjectedIncomeComparisonTableModifiedAdvice displayInflation={displayInflation} />
      <Spacer space='4px' />
      <TooltipParagraph>
        Ask yourself: Am I satisfied with the outcomes based on the changes I made?
      </TooltipParagraph>
      <SubHeading>Weak, average, and strong markets?</SubHeading>
      <TooltipParagraph>
        No one knows what market conditions will be like when you retire. But for simplicity, we've
        focused on the average market outcome, while providing upper and lower outcomes for stronger
        and weaker market conditions. And while it may be tempting to focus on the strong market
        outcome, conservatively speaking, if you can cover your needs with the weak market outcome,
        you know you're in great shape.
      </TooltipParagraph>
      <List>
        <li>
          <Bold>Weak market</Bold>: We think it's unlikely your result will be lower than this
          amount. We estimate your chances are 95 out of 100 that you'll have at least this much
          monthly income after retiring.
        </li>
        <li>
          <Bold>Average market</Bold>: We think you should focus on this amount. We estimate your
          chances are 2 out of 3 that you'll have at least this much monthly income after retiring.
        </li>
        <li>
          <Bold>Strong market</Bold>: Don't plan on more than this amount in retirement. We estimate
          you have an equal chance of having more or less than this much monthly income after
          retirement.
        </li>
      </List>
    </TooltipText>
  ),
  // Module 6
  percentIncomeSeekToReplace: () => (
    <TooltipText>
      <Heading>Percent of income seeking to replace after retiring</Heading>
      <TooltipParagraph>
        The GuidedChoice recommendation seeks to provide you with monthly spendable after-tax
        dollars equal to a certain percentage of your estimated household earnings.
      </TooltipParagraph>
      <PercentIncomeSeekToReplaceData />
      <TooltipParagraph>
        You can change your monthly income goal at any time by selecting the “Percent of income
        seeking after retiring” goal from the left-side navigation bar.
      </TooltipParagraph>
      <TooltipParagraph>
        *Estimated household earnings too high? Keep in mind, GuidedChoice does not know all of the
        deductions you may be taking that lower your take-home pay.
      </TooltipParagraph>
    </TooltipText>
  ),
  additionalSavings: () => (
    <TooltipText>
      <Heading>Additional savings</Heading>
      <TooltipParagraph>
        If GuidedChoice is recommending additional savings, this means that in order to provide for
        the income you want after retiring, as well as any other goals you've entered, it looks like
        you'll need to save even more than you are currently.
      </TooltipParagraph>
      <TooltipParagraph>
        The amount is over and above any contributions being made to an employer plan out of your
        paycheck (and your spouse's, if applicable), as well as other savings you've told us about.
      </TooltipParagraph>
      <AdditionalSavingsData />
      <TooltipParagraph>
        If you can manage this additional savings or even some portion thereof, that's great! But
        even if you can't at this time, don't lose hope, there's other options available. Test some
        different scenarios, such as retiring later or lowering the monthly income you're targeting.
      </TooltipParagraph>
    </TooltipText>
  ),
  preferredRangeInvestmentMix: () => (
    <TooltipText>
      <Heading>Preferred range of investment mix</Heading>
      <TooltipParagraph>
        GuidedChoice combines different kinds of assets, such as stocks, bonds, real estate, and
        cash, to create an investment strategy designed to help you reach your goals while limiting
        your exposure to risk. Our preferred range pertains to how we'd prefer you invest, not only
        in a current employer retirement plan account, but in all accounts for which you indicated
        you would like GuidedChoice to provide investment advice.
      </TooltipParagraph>
      <TooltipParagraph>
        Given it's a range, there are many points in between, but on either end of our preferred
        range for you…
      </TooltipParagraph>
      <InvestmentMixTable />
      <TooltipParagraph>
        The risk level we recommend may or may not be within our preferred range, but may be
        necessary in order to help you meet your goals. If you would be more comfortable staying
        within our preferred range, you can change your risk level so that it lies within our
        preferred and then adjust other variables, such as retirement age and savings, to determine
        what you'll need to do in order to meet your goals and still be within our preferred range.
        Just click the Make Changes button below to model different scenarios.
      </TooltipParagraph>
    </TooltipText>
  ),
  guideHistory: () => (
    <TooltipText>
      <Heading>Guide History</Heading>
      <TooltipParagraph>
        In addition to date-time records of your logins, we also keep record of the guides that are
        produced for you.
      </TooltipParagraph>
      <SubHeading>Numbers and codes explanation</SubHeading>
      <TooltipParagraph>
        As part of the PDF naming convention, we add the date and time that particular guide was
        created. For example: GuidedChoice-AdvisoryServiceGuide.04022018.090533.pdf, means that
        there was an Advisory Service guide created for you on April 2, 2018 at 9:05:33 AM.
      </TooltipParagraph>
      {/* <SubHeading>Emails and downloads</SubHeading>
      <TooltipParagraph>
        The E and D letters that follow your pdf simply indicate whether that
        pdf was downloaded and/or emailed to you.
      </TooltipParagraph>
      <SubHeading>Sent to recordkeeper</SubHeading>
      <TooltipParagraph>
        A yes or no under this column indicated whether transactions were sent
        to a recordkeeper or plan/account administrator.
      </TooltipParagraph> */}
    </TooltipText>
  ),
  changeSavingsOnly: () => (
    <TooltipText>
      <Heading>Change savings only</Heading>
      <TooltipParagraph>
        This is a list of the dates and times of transactions submitted where the change savings
        rate only functionality was used.
      </TooltipParagraph>
    </TooltipText>
  ),
  noEmailAddress: () => (
    <TooltipText>
      <Heading>No email address</Heading>
      <TooltipParagraph>
        Not to worry! Once we've created an Advisory Service Guide for you, it will be available for
        download via the Historical Data section of your Profile.
      </TooltipParagraph>
    </TooltipText>
  ),

  // Asset Class Breakdown Chart
  assetClassBreakdownChart: modifiedCase => (
    <TooltipText>
      <TooltipParagraph>
        The portfolio shown represents an ideal mix of the specific types of stock and/or bonds that
        should make up your {modifiedCase ? 'selected' : 'recommended'} GuidedChoice portfolio. Your
        company's plan may not offer all of the asset classes detailed in the ideal portfolio.
        GuidedChoice has made every effort to get as close to this mix as possible using your plan's
        investment options.
      </TooltipParagraph>
    </TooltipText>
  ),

  changeHowMuchInCompanyStock: () => (
    <TooltipText>
      <SubHeading>The “If implemented” chart shows 0%, why?</SubHeading>
      <TooltipParagraph>
        When GuidedChoice determines our initial recommendation for you, we assume you want to
        invest your entire account balance in an efficient portfolio—in other words, in our
        modeling, we've “sold off” the balance of your company stock and allocated the proceeds
        proportionately according to the portfolio we've created for you. However, you may not wish
        to sell out of the entire balance, or perhaps none at all.
      </TooltipParagraph>
      <TooltipParagraph>
        <SubHeading>Determining what's right for you</SubHeading>
      </TooltipParagraph>
      <TooltipParagraph>
        Go to Additional Planning or Continue Planning to experiment selling different percentages
        to see what the impact is on your projected income at retirement.
      </TooltipParagraph>
      <TooltipParagraph>
        When you are ready to implement your strategy, you can finalize how much, if any, you want
        to sell in Finalize Choices.
      </TooltipParagraph>
    </TooltipText>
  ),

  // Company Stock and Risk - Why we recommend selling 100%
  companyStockHundredPercent: () => (
    <TooltipText>
      <Heading>Why we recommend selling 100%</Heading>
      <TooltipParagraph>
        When GuidedChoice determines our initial recommendation for you, we assume you want to
        invest your entire account balance in an efficient portfolio—in other words, in our
        modeling, we've “sold off” the balance of your company stock and allocated the proceeds
        proportionately according to the portfolio we've created for you. However, you may not wish
        to sell out of the entire balance, or perhaps none at all. Note: any restricted investments
        are held automatically and you won't be able to model selling off portions until it becomes
        unrestricted.
      </TooltipParagraph>
    </TooltipText>
  ),

  // Company Stock and Risk - Determining what's right for you
  companyStockDetermination: () => (
    <TooltipText>
      <Heading>Determining what's right for you</Heading>
      <TooltipParagraph>
        Experiment selling different percentages to see what the impact is on your projected income
        at retirement, as well as your level of risk. Quickly, you can determine the amount to keep
        that makes the most sense in the long run.
      </TooltipParagraph>
      <TooltipParagraph>
        For instance, you might first change the amount to sell to 0%, so you're keeping all of it.
        Don't change any of the other parameters. Click recalculate to see the projected income.
        Save this scenario as something like “sell no stock.” Then return to Make Changes and model
        selling off just a portion, e.g. 25%. Again, changing nothing else and recalculating will
        give you your projected results based on selling off whatever portion you indicated. Save
        this scenario as “sell 25% stock.” Then access the scenarios and compare the results. If
        there isn't a lot of difference in the projections, you know you can afford to keep that
        much stock. But if there is a lot of difference, return to Make Changes and try selling off
        even more, again saving and comparing results. By changing variables and comparing the
        projected income from our recommendation to your modifications, you can come to a plan that
        best works for you.
      </TooltipParagraph>
    </TooltipText>
  ),

  // Vested vs. Unvested Stock Options
  stockOptions: () => (
    <TooltipText>
      <Heading>Vested vs. unvested options</Heading>
      <TooltipParagraph>
        Vested options refer to those stock option grants that the optionee now has the option to
        exercise—to purchase—until the expiration date. GuidedChoice automatically includes in
        retirement income projections any vested options that are not under water (shares whose
        exercise price is higher that the stock's current price).
      </TooltipParagraph>
      <TooltipParagraph>
        Unvested options are grants that have not yet “matured”; in other words, enough time hasn't
        passed since the grant was awarded for the optionee to purchase the associated shares.
        GuidedChoice does not include unvested options, as they have no value until vested.
      </TooltipParagraph>
    </TooltipText>
  ),

  // Managed account service
  managedAccount: () => (
    <TooltipText>
      <Heading>Managed account</Heading>
      <TooltipParagraph>
        Allow GuidedChoice to make investment choices tailored to your needs and available funds,
        then enjoy automatic monitoring and quarterly rebalancing of your account to keep you on
        track. You'll be given the opportunity to become a managed account holder in the review
        process after receiving recommendations.
      </TooltipParagraph>
    </TooltipText>
  ),

  // Discontinue managed account services
  discontinueManagedAccount: () => (
    <TooltipText>
      <Heading>When you don’t have a managed account…</Heading>
      <TooltipParagraph>
        It is, of course, entirely up to you to decide whether or not a managed account is right for
        you. Should you elect to opt out of the managed account service, keep in mind that
        rebalancing and reoptimizing your account on a regular basis is critical to growing your
        assets over time. This means you need to monitor your investments to determine the
        appropriate changes to your strategy due to changes in the economy or your financial
        situation.
      </TooltipParagraph>
      <TooltipParagraph>
        You are welcome to continue using the free portion of the Advisory Service for guidance,
        which includes an investment allocation at the asset class level. Use this allocation as a
        second opinion or even to help guide you in selecting your investments from the available
        options.
      </TooltipParagraph>
    </TooltipText>
  ),

  // Point-in-time advice service
  pointInTimeAdvice: () => (
    <TooltipText>
      <Heading>Point-in-time advice</Heading>
      <TooltipParagraph>
        Allow GuidedChoice to make investment choices tailored to your needs and available funds,
        then enjoy automatic monitoring and annual rebalancing of your account to keep you on track.
        You'll be given the opportunity to become a point-in-time advice client in the review
        process after receiving recommendations.
      </TooltipParagraph>
    </TooltipText>
  ),

  // Automatic Risk Level Adjustment
  autoRiskAdjustment: () => (
    <TooltipText>
      <Heading>Automatic risk level adjustment</Heading>
      <TooltipParagraph>
        You have the option of giving GuidedChoice permission to adjust your risk level
        automatically over time. This is a process that is in addition to the automatic rebalancing
        we do for your account each quarter.
      </TooltipParagraph>
      <Heading>What's the difference?</Heading>
      <TooltipParagraph>
        When we rebalance, we bring your investments back into alignment with the risk level you
        accepted. The optional risk level adjustment service, however, is an annual process whereby
        we re-run your data through our engine to make sure you are in the correct risk level to
        help ensure reaching your retirement income goal. If you are not, we will place you in a new
        risk level, automatically.
      </TooltipParagraph>
      <TooltipParagraph>
        Include or exclude this option in your profile data. There is no extra fee for this service.
      </TooltipParagraph>
    </TooltipText>
  ),

  // Personalized Target Date+
  personalizedTargetDatePlus: () => (
    <TooltipText>
      <Heading>Personalized Target Date+</Heading>
      <TooltipParagraph>
        This GuidedChoice service uses mainly your employer plan account to create your savings and
        investing strategy for income after retiring. GuidedChoice selects and manages your
        account's investments at the contribution rate you choose, and creates a personalized glide
        path for you, adjusting your level of risk automatically as your retirement age approaches.
      </TooltipParagraph>
    </TooltipText>
  ),

  projectedIncomeAndSources: config => (
    <TooltipText>
      <Heading>About your projected income</Heading>
      <TooltipParagraph>
        Intuitively, you understand that there is no way for us to know the exact market conditions
        when you retire. But using powerful simulation technology and robust analytics, GuidedChoice
        projects hundreds of possible market scenarios and then determines their impact on your
        projected retirement income. We then measure the likelihood that any one outcome would
        occur.
      </TooltipParagraph>
      <TooltipParagraph>
        The probability of an outcome is what we expect the frequency of any one outcome to be after
        running several hundred different scenarios. The single number displayed is the “average”
        market outcome in today's after-tax dollars. We estimate your chances are 2 out of 3 that
        you'll have at least this much monthly income after retiring.
      </TooltipParagraph>
      <Heading>Income sources included</Heading>
      <TooltipParagraph>
        Your projected monthly income after retiring includes the following income sources:
      </TooltipParagraph>
      <TooltipParagraph>
        &bull; Your investment plan account with {config.template.sponsorName}
        <br />
        &bull; Your pension plan with {config.template.sponsorName}
        <br />
        &bull; Social Security income as estimated by GuidedChoice
      </TooltipParagraph>
    </TooltipText>
  ),

  yesInvestForMe: () => (
    <TooltipText>
      <Heading>Yes, invest for me — Is it for you? What you get</Heading>
      <TooltipParagraph>This option is best for you if:</TooltipParagraph>
      <div>
        <span
          css={css`
            font-weight: bold;
          `}>
          &bull;
        </span>{' '}
        Your only retirement asset is your account with your current employer (and Social Security).
        <br />
        <span
          css={css`
            font-weight: bold;
          `}>
          &bull;
        </span>{' '}
        Right now, planning is about making sure that you're invested wisely. Retirement is “out
        there” at some future point and you know you have to save for it, but you really would
        prefer it be taken care of for you.
        <br />
        <span
          css={css`
            font-weight: bold;
          `}>
          &bull;
        </span>{' '}
        Experimenting with different contribution rates and retirement ages is all the scenario
        planning you need.
      </div>
      <TooltipParagraph>
        Should you elect this option, you will be enrolled in the GuidedChoice Personalized Target
        Date+, where GuidedChoice will select and manage your account's investments at the
        contribution rate you choose, and create a personalized glide path for you, adjusting your
        level of risk automatically as needed.
      </TooltipParagraph>
    </TooltipText>
  ),

  fullPlanningService: () => (
    <TooltipText>
      <Heading>Full planning service—Is it for you? What you get</Heading>
      <TooltipParagraph>This option is best for you if:</TooltipParagraph>
      <div>
        <span
          css={css`
            font-weight: bold;
          `}>
          &bull;
        </span>{' '}
        You have additional retirement accounts, e.g. previous employer accounts, IRAs, pensions,
        spousal accounts, that you would like considered in the creation of your savings and
        investing strategy.
        <br />
        <span
          css={css`
            font-weight: bold;
          `}>
          &bull;
        </span>{' '}
        You are interested in having a strategy that takes into consideration all the accounts you
        have so that they are all working towards the goals you have for them. To this end, you are
        interested in getting savings advice not only for this account but for a spouse's account
        and/or other retirement accounts.
        <br />
        <span
          css={css`
            font-weight: bold;
          `}>
          &bull;
        </span>{' '}
        You are interested in understanding the impact of a variety of factors, such as:
        <div
          css={css`
            padding-left: 20px;
          `}>
          <span
            css={css`
              font-weight: bold;
            `}>
            &bull;
          </span>{' '}
          Ongoing savings into more than just your current employer plan account, but a spouse's
          account and/or other type of account, such as an IRA
          <br />
          <span
            css={css`
              font-weight: bold;
            `}>
            &bull;
          </span>{' '}
          Retirement ages for you and your spouse
          <br />
          <span
            css={css`
              font-weight: bold;
            `}>
            &bull;
          </span>{' '}
          Including financial goals other than retirement, such as college or downpayment on a home
          <br />
          <span
            css={css`
              font-weight: bold;
            `}>
            &bull;
          </span>{' '}
          More or less aggressive investment strategies
        </div>
      </div>
      <TooltipParagraph>
        If you select this option, you'll be placed in a managed account and given access to our
        full planning service to add personal and account information. The more information you are
        able to give us, the better the personalization of our advice. As a managed account holder,
        you will enjoy one-click implementation, ongoing account management, and automatic
        rebalancing.
      </TooltipParagraph>
      <TooltipParagraph>
        Please note: Once you enroll in a managed account with full planning privileges, you cannot
        return to planning with your employer plan account only.
      </TooltipParagraph>
    </TooltipText>
  ),

  laterRetirementAgeExpected: () => (
    <TooltipText>
      <Heading>Retirement age later than expected?</Heading>
      <TooltipParagraph>
        We've run hundreds of scenarios to find a strategy that will provide the income you want
        most of the time you are in retirement. And when the strategy includes a later retirement
        age than you prefer, it means that increasing savings and changing your investments is not
        enough.
      </TooltipParagraph>
      <TooltipParagraph>
        If you are unwilling or unable to continue working till the retirement age recommended, you
        may need to adjust the income you want after retiring—as well as reconsider any other
        financial goals you have included.
      </TooltipParagraph>
    </TooltipText>
  ),

  electronicDeliveryConsent: () => (
    <TooltipText>
      <Heading>Electronic delivery consent</Heading>
      <TooltipParagraph>
        Plan communications, such as the GuidedChoice annual summary, are sent via email,
        automatically. You cannot opt out of these communications, though you may revoke your
        consent to electronic delivery. Keep in mind, however, some plan communications will be
        available electronically only and if you turn off your consent, we may charge a fee to
        provide a hard copy version. Please see the Disclosure Document for details.
      </TooltipParagraph>
      <TooltipParagraph>
        Rarely, other email communications, such as reminders or announcements, may be sent. They
        are still important, though not lawfully required, and therefore you may revoke your
        permission to receive them. Typically, these kinds of communications will be available
        electronically only.
      </TooltipParagraph>
    </TooltipText>
  ),

  catchupContributions: () => <CatchupContributionLimits />,

  replacementIncomePTDPlus: () => (
    <TooltipText>
      <Heading>Projected replacement income</Heading>
      <TooltipParagraph>
        The GuidedChoice recommendation seeks to help you have at least 80% of your current income
        each month after retiring. We want you to be able to rely on this income from retirement
        through mortality age plus ten years.
      </TooltipParagraph>
    </TooltipText>
  ),

  whyContributionRecIsZero: () => (
    <TooltipText>
      <Heading>Why contribution rate recommendation is 0% | $0</Heading>
      <TooltipParagraph>
        There are two likely reasons why we are recommending a contribution rate of 0%|$0:
      </TooltipParagraph>
      <TooltipParagraph>
        &bull; You are not contributing currently and are meeting or exceeding the income
        replacement goal of 80%; or,
        <br />
        &bull; You have reached your selected retirement age.
      </TooltipParagraph>
      <TooltipParagraph>
        If it is the latter and you have not yet retired, access your profile
        <AvatarBox>
          <InlineSVG src={avatar} element='span' />
        </AvatarBox>
        and update your retirement age to an age in the future.
      </TooltipParagraph>
    </TooltipText>
  ),

  plaidHelpContent: () => (
    <TooltipText>
      <Heading>What do I need to know regarding linking an account?</Heading>
      <TooltipParagraph>
        Linking an account is a process that provides a way to update your investments
        electronically at the click of a button. GuidedChoice uses a third-party, Plaid, to
        facilitate this process.
      </TooltipParagraph>
      <TooltipParagraph>
        Sounds great—and it is—but there a couple potential downsides you should be aware of.
      </TooltipParagraph>
      <TooltipParagraph>
        1. If your account has investments that were purchased using Roth or after-tax contributions
        (as opposed to pre-tax contributions), the data we receive for import will not distinguish
        among them; it will put the entire balance under pre-tax. The first time data is imported
        and then each time after the account is refreshed, you will see an alert reminding you to
        split the balance among pre-tax and/or Roth and/or after-tax contributions.
      </TooltipParagraph>
      <TooltipParagraph>
        Example: Let's say your plan rules allow you to make both pre-tax and Roth contributions to
        your account and you are taking advantage of this feature. This means that your account will
        have a pre-tax balance and a Roth balance making up the total account balance. However, when
        we receive the data to import from your financial institution, the entire balance will show
        as pre-tax, which could either understate or overstate your projected income. You will have
        to manually update your account's information in our service each time your account is
        refreshed. We will display an alert message if your account data is affected.
      </TooltipParagraph>
      <TooltipParagraph>
        2. If you add an investment manually after importing account data, that manually added
        investment will disappear as soon as you refresh that account's data, as long as the account
        is still linked. This means you'll have to re-add the investment.
      </TooltipParagraph>
      <Heading>Who is Plaid?</Heading>
      <TooltipParagraph>
        Plaid is the service provider we use that provides the link between us and your financial
        institution to retrieve your account's investment data. Your account credentials are
        verified through and kept by Plaid. GuidedChoice has no access to your account or to your
        login information.
      </TooltipParagraph>
      <TooltipParagraph>
        By linking your account, you are authorizing GuidedChoice, and by extension Plaid, to
        connect with your financial institution to retrieve current investment information.
      </TooltipParagraph>
    </TooltipText>
  ),

  plaidSplitBalances: () => (
    <TooltipText>
      <Heading>Why do I need to split my investment balances?</Heading>
      <TooltipParagraph>
        When investment data is imported, the information we receive from your financial institution
        aggregates the total of each investment into all pre-tax. However, your account information
        shows that you may also have Roth and/or after-tax investments. It is in your best interest
        to divide the balance as appropriate, as it could have a significant impact on your
        projected income results⁠—either over- or under-stating your monthly income⁠—and our
        recommendation.
      </TooltipParagraph>
    </TooltipText>
  ),

  atmosMobileNumber: () => (
    <TooltipText>
      <Heading>Why must this be a mobile number?</Heading>
      <TooltipParagraph>
        If you prefer to get a text rather than an email to authenticate your identity for our
        systems, we must have a mobile phone number. It is not required if you prefer an email.
      </TooltipParagraph>
    </TooltipText>
  ),

  atmosEmail: () => (
    <TooltipText>
      <Heading>Login email</Heading>
      <TooltipParagraph>
        A valid email address will be needed for use as your username. In terms of security and
        permanency of email address, we highly recommend using a personal email rather than a
        work-related one.
      </TooltipParagraph>
    </TooltipText>
  ),

  authenticationMethod: () => (
    <TooltipText>
      <Heading>Select authentication method</Heading>
      <TooltipParagraph>
        GuidedChoice uses what's called two-factor authentication, which provides a second layer of
        security beyond a password. We will send you a code to enter by text or email as selected,
        that will validate your identity for our systems.
      </TooltipParagraph>
      <TooltipParagraph>
        Please be certain that you have access to the email or cell phone entered as the code is
        valid for five minutes only.
      </TooltipParagraph>
      <TooltipParagraph>
        If you choose text, the phone number entered should be able to receive text messages.
        Standard text message rates apply
      </TooltipParagraph>
    </TooltipText>
  ),

  fullRetirementAge: () => (
    <TooltipText>
      <Heading>Full retirement age</Heading>
      <TooltipParagraph>
        Full retirement age is the age at which you can begin receiving unreduced Social Security
        income. You can receive higher amounts of Social Security income if you are willing to wait
        a little longer. You can delay until age 70 and for every additional year you wait, your
        benefit will be another 8 percentage points higher than if you had started at your full
        retirement age..
      </TooltipParagraph>
    </TooltipText>
  ),

  annualSalary: () => (
    <TooltipText>
      <Heading>Annual salary</Heading>
      <TooltipParagraph>
        It might seem odd that we're asking about annual salary if you're already in retirement, but
        we need it to help us in our estimation of Social Security income.
      </TooltipParagraph>
    </TooltipText>
  ),

  optimizeSSI: () => (
    <TooltipText>
      <Heading>What does it mean to optimize my Social Security income?</Heading>
      <TooltipParagraph>
        Our Social Security optimizer considers all possible starting ages for you and your spouse,
        when applicable, to begin taking Social Security and chooses the age combination with the
        highest present value of expected benefits. It knows the Social Security rules and formulas
        for retirement and survivor benefit amounts beginning at different ages, and considers your
        and your spouse's chances of surviving to each age up to 114.
      </TooltipParagraph>
    </TooltipText>
  ),

  additionalPlanning: () => (
    <TooltipText>
      <Heading>Additional planning</Heading>
      <TooltipParagraph>
        <InnerHeading>Budgeting</InnerHeading>—Set up the amount you need and want.
      </TooltipParagraph>
      <TooltipParagraph>
        <InnerHeading>Social Security optimization</InnerHeading>
      </TooltipParagraph>
      <TooltipParagraph>
        <InnerHeading>Strategic planning considerations</InnerHeading>—What if you live till 102?
        What if your spouse dies today? What if you set up an emergency fund? You can see results of
        these kinds of scenarios and more in determining your retirement income strategy.
      </TooltipParagraph>
    </TooltipText>
  ),

  fundingAnnuity: () => (
    <TooltipText>
      <Heading>GuidedChoice selects the accounts to fund the annuity purchase</Heading>
      <TooltipParagraph>
        When modeling the purchase of an annuity, we will first look to use assets in employer
        plans. The reason for this is that typically plans offer the lowest cost options. If that is
        not an option or if there is not enough money in the plans to fund the desired monthly
        income amount, then we look to use assets from taxable accounts.
      </TooltipParagraph>

      <ul>
        <li>
          <TooltipParagraph>
            If your annuity is tax-advantaged (aka "qualified"), that means you can purchase it
            using pre-tax funds from your tax-deferred retirement account without having to pay
            taxes on the purchase transaction. The IRS treats this type of annuity as a rollover to
            an IRA, which is not taxable even if done properly. However, when you begin receiving
            annuity income you will have to pay taxes on those payments as ordinary income.
          </TooltipParagraph>
        </li>
        <li>
          <TooltipParagraph>
            If your annuity is taxable (aka "non-qualified"), that means you purchase it with
            after-tax dollars. After-tax dollars may come from a taxable annuity by withdrawing from
            a tax-deferred account (such as your retirement plan), you will have to pay taxes on the
            withdrawal, which can make this purchase expensive. However, the subsequent annuity
            payments may entail very little tax until you outlive your life expectancy.
          </TooltipParagraph>
        </li>
      </ul>
    </TooltipText>
  ),

  availableRemainingInvestmentBalance: () => (
    <TooltipText>
      <Heading>Available investment balance</Heading>
      <TooltipParagraph>
        The available investment balance includes the total of all retirement accounts for you, and
        if included in planning, your spouse, that can be converted or rolled over into a
        tax-advantaged (aka “qualified”) annuity, rather than withdrawn to purchase a taxable (aka
        “non-qualified”) annuity. We do this for tax efficiency purposes.
      </TooltipParagraph>
      <TooltipParagraph>
        This means we will not include balances, if you have them, from the following accounts:
        <br />
      </TooltipParagraph>
      <ul>
        <li>Roth IRAs</li>
        <li>
          Designated Roth accounts (e.g., the portion of a defined contribution plan account that is
          attributable to Roth contributions)
        </li>
        <li>Non-deductible IRAs</li>
        <li>Brokerage accounts</li>
        <li>
          “Other Asset” (meaning an asset that doesn’t fit any of the categories we have for account
          type)
        </li>
      </ul>

      <TooltipParagraph>
        You can, of course, withdraw from these assets to get cash to pay for your annuity, but keep
        in mind, you might have to pay taxes on the withdrawal, which can make your annuity purchase
        expensive, and since part of our objective is to advise you in a tax-efficient manner, we
        exclude these accounts.
      </TooltipParagraph>

      <Heading>Remaining investment balance </Heading>
      <TooltipParagraph>
        The “remaining investment balance” applies when an annuity purchase is modeled for you and
        your spouse. It is what's left of the available investment balance for your spouse's annuity
        after the annuity for you has been modeled.
      </TooltipParagraph>
    </TooltipText>
  ),

  budgetNeed: () => (
    <TooltipText>
      <Heading>Need—aka mandatory expenses to consider</Heading>
      <TooltipParagraph>
        <InnerHeading>Debt</InnerHeading>
        <br />
        <LeftPadding />
        Mortgage
        <br />
        <LeftPadding />
        Home equity loan (second mortgage)
        <br />
        <LeftPadding />
        Credit card payments
        <br />
        <LeftPadding />
        Car payments
        <br />
        <LeftPadding />
        Alimony
        <br />
        <LeftPadding />
        Student loans
        <br />
      </TooltipParagraph>
      <TooltipParagraph>
        <InnerHeading>Housing & utilities</InnerHeading>
        <br />
        <LeftPadding />
        Rent
        <br />
        <LeftPadding />
        Property taxes
        <br />
        <LeftPadding />
        Homeowner's insurance
        <br />
        <LeftPadding />
        Renter's insurance
        <br />
        <LeftPadding />
        Catastrophic event insurance (e.g., flood, earthquake)
        <br />
        <LeftPadding />
        Gas & electric
        <br />
        <LeftPadding />
        Water
        <br />
        <LeftPadding />
        Garbage
        <br />
        <LeftPadding />
        Phone
        <br />
        <LeftPadding />
        Maintenance & repairs
        <br />
      </TooltipParagraph>
      <TooltipParagraph>
        <InnerHeading>Transportation</InnerHeading>
        <br />
        <LeftPadding />
        Car Insurance
        <br />
        <LeftPadding />
        Gas
        <br />
        <LeftPadding />
        Oil & repairs
        <br />
        <LeftPadding />
        Tolls & parking
        <br />
        <LeftPadding />
        State registration fees
        <br />
        <LeftPadding />
        Bus/subway fares
        <br />
      </TooltipParagraph>
      <TooltipParagraph>
        <InnerHeading>Medical</InnerHeading>
        <br />
        <LeftPadding />
        Medical insurance premium
        <br />
        <LeftPadding />
        Prescription drug plan premium
        <br />
        <LeftPadding />
        Dental insurance premium
        <br />
        <LeftPadding />
        Vision insurance premium
        <br />
        <LeftPadding />
        Co-payments & expected out-of-pocket expenses <br />
        <LeftPadding />
        Uncovered prescription drug expenses
        <br />
        <LeftPadding />
        Other medical
        <br />
        <LeftPadding />
        Other medical expenses
        <br />
      </TooltipParagraph>
      <TooltipParagraph>
        <InnerHeading>Grocery, household & personal care</InnerHeading>
        <br />
        <LeftPadding />
        Groceries
        <br />
        <LeftPadding />
        Household items
        <br />
        <LeftPadding />
        Laundry & dry cleaning
        <br />
        <LeftPadding />
        Personal care products
        <br />
        <LeftPadding />
        Clothing
        <br />
      </TooltipParagraph>
      <TooltipParagraph>
        <InnerHeading>Child care & support of relatives</InnerHeading>
        <br />
      </TooltipParagraph>
    </TooltipText>
  ),

  budgetWant: () => (
    <TooltipText>
      <Heading>Want—aka non-essential spending to consider</Heading>
      <TooltipParagraph>
        Restaurants, take-out
        <br />
        Pet expenses
        <br />
        Sport equipment, venue fees
        <br />
        Hobbies
        <br />
        Charitable contributions, tithing
        <br />
        Club memberships
        <br />
        Internet, cable TV
        <br />
        Movies, concerts, theater
        <br />
        Newspapers, magazines, books
        <br />
        Vacations, travel
        <br />
        Gifts, e.g. birthdays, holidays
        <br />
        Tuition
        <br />
        Allowance for dependents
        <br />
        Home improvements
        <br />
        Miscellaneous/cash spending
        <br />
      </TooltipParagraph>
    </TooltipText>
  ),

  budgetConsiderations: () => (
    <TooltipText>
      <Heading>Other budgeting considerations</Heading>
      <TooltipParagraph>
        <InnerHeading>End-of-life expenses</InnerHeading>
        <br />
        The important thing to ask yourself is when do you expect to pay for end-of-life expenses?
        Are you purchasing a pre-need funeral plan or are you just planning on setting aside a
        certain amount of your estate for end-of-life expenses?
        <br />
        <br />
        If this is a pre-need funeral plan you're purchasing now (or planning to purchase soon), we
        suggest adding this expense as a goal. That way, you can input the year you plan to buy, as
        well as the amount you expect to spend, and GuidedChoice will know that money will not be
        available for expenses in retirement and will stop considering that amount in our
        projections from that year forward.
        <br />
        <br />
        If you just want to set aside a certain amount from your estate to be used for end-of-life
        expenses, then entering that figure here makes the most sense.
        <br />
        <br />
      </TooltipParagraph>
      <TooltipParagraph>
        <InnerHeading>Bequest</InnerHeading>
        <br />
        GuidedChoice considers bequests upon death so the money you wish to leave to another is not
        part of any remaining balance for the estate to settle.
      </TooltipParagraph>
    </TooltipText>
  ),

  budgetWarning: () => (
    <TooltipText>
      <Heading>To be written</Heading>
      <TooltipParagraph>To be written</TooltipParagraph>
    </TooltipText>
  ),
  homeToggle: () => (
    <TooltipText>
      <Heading>Toggle: Are you accumulating or spending your retirement savings?</Heading>
      <TooltipParagraph>
        The piggy bank toggle lets us know what kinds of information is appropriate to display on
        the Home page, which also directs what version of Overall Strategy you get and what we can
        transact on.
      </TooltipParagraph>
      <Heading>
        Accumulating &nbsp;
        <SVGWrapper
          svg={PiggyBank}
          fill='#059FC6'
          hoverFill='#059FC6'
          size='large'
          cursor='default'
        />
      </Heading>
      <TooltipParagraph>
        If you are still accumulating savings for retirement, you want to keep the toggle on the
        saving side, where the piggy bank has money going in...
        <br />
        <br />
        You are not yet retired but are getting close and you want to model spend down scenarios to
        get an idea of your strategy once you do retire.
        <br />
        <br />
        Note: No transactions will take place while modeling spend down scenarios.
      </TooltipParagraph>
      <Heading>
        Spending &nbsp;
        <SVGWrapper
          svg={PiggyBank}
          fill='#7A8E96'
          hoverFill='#7A8E96'
          size='large'
          cursor='default'
          css={css`
            transform: scale(-1, -1);
          `}
        />
      </Heading>

      <TooltipParagraph>
        If you want withdrawal advice you're ready to use immediately or are already spending your
        retirement savings, you want to switch the toggle to the spending side, where the piggy bank
        has money coming out...
        <br />
        <br />
        You are ready to turn your savings into regular retirement income..
        <br />
        <br />
      </TooltipParagraph>
    </TooltipText>
  ),
  whatIsSpendDownModeling: () => (
    <TooltipText>
      <Heading>What is spend down modeling?</Heading>
      <TooltipParagraph>
        When you are within three years of your retirement, you may wish to get a better idea of
        your strategy for turning your savings into regular retirement income that will last. You
        can do that by modeling spend down scenarios, e.g. what if I live to 105? What if my spouse
        passes this year? And should I buy an annuity? We won't transact on what you model or give
        you a guide at the end since it's not time for withdrawal advice, but you will get to use
        all the functionality available in our spend down service.
      </TooltipParagraph>
    </TooltipText>
  ),
  areYouRetired: () => (
    <TooltipText>
      <Heading>Next steps—Are you now retired?</Heading>
      <TooltipParagraph>
        <InnerHeading>If you're still getting a paycheck</InnerHeading>&nbsp; and thus not yet
        retired, please go to your profile{' '}
        <span>
          <CustomButton style={{ padding: 0, maxHeight: '16px' }}>
            <SVGWrapper
              svg={Avatar}
              fill='#7a8e96'
              hoverFill='#7a8e96'
              size='medium'
              cursor='default'
              style={{ verticalAlign: 'top' }}
            />
          </CustomButton>
        </span>{' '}
        and update your retirement age to an age in the future. We'll focus on those things that are
        important to you while you continue to accumulate retirement savings.
      </TooltipParagraph>
      <TooltipParagraph>
        Until you retire though, you have access to our spend-down module—just click the Spend-down
        modeling button. But please leave the toggle on the upright piggy bank!&nbsp;
        <ButtonContainer style={{ maxWidth: '48px', maxHeight: '21px' }}>
          <CustomButton active style={{ padding: '0px 5px' }}>
            <SVGWrapper
              svg={PiggyBank}
              fill='#059FC6'
              hoverFill='#059FC6'
              size='small'
              cursor='default'
            />
          </CustomButton>
          <CustomButton active={false} flipped style={{ padding: '0px 5px' }}>
            <SVGWrapper
              svg={PiggyBank}
              fill='#7A8E96'
              hoverFill='#7A8E96'
              size='small'
              cursor='default'
            />
          </CustomButton>
        </ButtonContainer>
      </TooltipParagraph>
      <TooltipParagraph>
        <InnerHeading>If you're no longer getting a paycheck</InnerHeading>&nbsp; and are retired,
        you should have a strategy to make your money last. We can provide withdrawal advice that
        seeks to do just that. Go ahead and switch that toggle to the upside down piggy bank.&nbsp;
        <ButtonContainer style={{ maxWidth: '48px', maxHeight: '21px' }}>
          <CustomButton active={false} style={{ padding: '0px 5px' }}>
            <SVGWrapper
              svg={PiggyBank}
              fill='#059FC6'
              hoverFill='#059FC6'
              size='small'
              cursor='default'
            />
          </CustomButton>
          <CustomButton active flipped style={{ padding: '0px 5px' }}>
            <SVGWrapper
              svg={PiggyBank}
              fill='#7A8E96'
              hoverFill='#7A8E96'
              size='small'
              cursor='default'
            />
          </CustomButton>
        </ButtonContainer>
        &nbsp; We'll then start focusing on those things that are important to you now that you're
        in retirement.
      </TooltipParagraph>
    </TooltipText>
  ),
  lumpSumPensionPayout: () => (
    <TooltipText>
      <Heading>Lump sum pension payout</Heading>
      <TooltipParagraph>
        <InnerHeading>To update your payout status:</InnerHeading> Click on the lump sum pension
        name in the left side menu. When you get to the account summary page, click to edit the
        payout details and change your answer to yes, you’ve received payment.
      </TooltipParagraph>
      <TooltipParagraph>
        <InnerHeading>
          Important: Did you invest the money or use it for something else?
        </InnerHeading>{' '}
        If you’ve used it already for another purpose, that’s fine. There’s nothing else to do after
        updating your payout status. But if you still have it, we suggest investing it in a
        tax-advantaged account, such as an IRA, and then letting us know where you invested so that
        we know it’s available for future retirement expenses. If you added it to an existing
        account, just update your balance. If you opened a new account, tell us about it.
      </TooltipParagraph>
    </TooltipText>
  ),
  lumpSumPensionPayoutWhereMoneyComesFrom: () => (
    <TooltipText>
      <Heading>Lump sum pension payout</Heading>
      <TooltipParagraph>
        Our projections assume that you will invest your lump sum amount in a tax-advantaged
        account, such as an IRA, so that it may be used for future retirement expenses.
      </TooltipParagraph>
      <TooltipParagraph>
        When you reach the age you expect payment, we will remind you to let us know what you
        decided to do with the money so that we know whether or not to continue to include it in our
        projections or not.
      </TooltipParagraph>
    </TooltipText>
  ),
  updateAccountContributionsAfterRetirement: () => (
    <TooltipText>
      <TooltipParagraph>
        <InnerHeading>Since you have retired&mdash;congratulations!&mdash;</InnerHeading>it would be
        good to make sure all the information you've entered is up-to-date.
      </TooltipParagraph>
      <TooltipParagraph>
        Expand the left menu and you may notice some piggy banks next to account balances. This
        piggy bank is meant to signify that you are contributing to this account ongoing. But since
        retiring, your contributions have likely stopped.{' '}
      </TooltipParagraph>
      <TooltipParagraph>
        Click on each account name where there is a piggy bank to go to that account's summary page.
        From there, go to the "Paycheck contributions" or "Contributions" tile and edit the
        "contributing now" or "annual contribution" field.
      </TooltipParagraph>
      <TooltipParagraph>
        <InnerHeading>Note: </InnerHeading>
        Your latest employer plan account may still reflect a piggy bank. If so, this is because
        they have not sent us a code letting us know that you have terminated employment and are
        thus not contributing. They are the source of authority for this account's information so
        there is nothing you can do other than ignore it. This "error" will not affect your
        projections, just the display in the left menu bar.
      </TooltipParagraph>
    </TooltipText>
  ),
  emergencyFundHelp: () => (
    <TooltipText>
      <Heading>Emergency Fund</Heading>
      <TooltipParagraph>
        We treat your emergency fund amount as reserves within your retirement funds, through the
        end of your planning period, without changing the allocation. This is the same way we treat
        bequests. Should you require the emergency fund at any time during your retirement, return
        to the GuidedChoice service and enter the amount you need as a goal payable this calendar
        year. Your new guide will contain withdrawal instructions that include the amount you need
        immediately.
      </TooltipParagraph>
    </TooltipText>
  ),
  investmentBalance: () => (
    <TooltipText>
      <Heading>Investment Balance</Heading>
      <TooltipParagraph>
        Your investment balance does not take into consideration the cost of your modeled
        annuity(ies).
      </TooltipParagraph>
    </TooltipText>
  ),
  cvv: () => (
    <TooltipText>
      <Heading>CVV — Card Verification Value or security code</Heading>
      <TooltipParagraph>
        The card security code is located on the back of Visa®, MasterCard®, Discover®, Diners
        Club®, and JCB® cards. It is typically 3 digits long and placed to right of the signature
        strip.
      </TooltipParagraph>
      <TooltipParagraph>
        American Express® cards use a 4-digit code placed above the end of the account number on the
        front of the card.
      </TooltipParagraph>
    </TooltipText>
  ),
  accountHolderEmail: () => (
    <TooltipText>
      <Heading>Account holder email</Heading>
      <TooltipParagraph>
        The email address entered should be for the person who should receive confirmations and
        other communications about the GuidedChoice account
      </TooltipParagraph>
    </TooltipText>
  ),

  selectInvestmentAccount: () => (
    <TooltipText>
      <Heading>Why isn't my account listed?</Heading>
      <TooltipParagraph>
        If you know you added the account, it will not be listed if: <br />
        <br />
        1. It is not an investment account but an expected income account, such as a pension or an
        annuity; or,
        <br />
        <br />
        2. You did not elect to include investment advice for that particular account. If this is
        the case, it's easy to have investment advice included by selecting that account from the
        left menu and clicking the Edit link on the Account Basics tile.
      </TooltipParagraph>
    </TooltipText>
  ),

  whyDoesThisMatter: () => (
    <TooltipText>
      <Heading>Why does this matter?</Heading>
      <TooltipParagraph>
        Your organization added a retirement plan for you and has entered information about allowed
        contribution types, any employer match, and available investments.
        <br /> <br />
        <strong>If the retirement plan you already created is now a duplicate</strong>, we recommend
        deleting the old account. This means you may have to re-enter some information, but it will
        be up to date with what your organization told us. <br /> <br />
        <strong>If it's not a duplicate</strong>, we will treat the new one as the account you can
        contribute to.
      </TooltipParagraph>
    </TooltipText>
  ),

  whyDoesTheTotalContributionAppearToBeMoreThanWhatsAllowed: () => (
    <TooltipText>
      <Heading>Why does the total contribution appear to be more than what's allowed?</Heading>
      <div>
        The amount displayed here may look as though we are allowing a greater contribution total
        than what's permitted, but we really aren't. Contributions are annualized but you may not
        have an entire year to spread your contributions evenly.
        <br />
        <ul>
          <li>
            Case 1: You want to make up for time during the current calendar year during which you
            either couldn't or didn't contribute, e.g., you changed jobs and couldn't contribute to
            a plan account for three months and want to “make up” for that time.
          </li>
          <li>
            Case 2: You want to “front-load” your account balance, meaning you want to contribute at
            a higher rate now so that you can contribute at a lower rate later in the year, e.g.,
            you want more cash in your paycheck later in the year for the holidays.
          </li>
        </ul>
      </div>
      <TooltipParagraph>
        If you are considering front-loading, we want to be sure you understand the possible
        implications of this decision. Please go to Help: Accounts: What is front-loading your
        employer plan account?
      </TooltipParagraph>
    </TooltipText>
  ),
}
