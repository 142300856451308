import { Route, Switch } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import AuthCheck from './AuthCheck'
import { Onboarding, Main, Offboarding } from './views'
import UpgradeProduct from './views/offboarding/upgrade-product/UpgradeProduct'
import Cancellation from './views/offboarding/transactions/Cancellation'
import Discontinue from './views/profile/services/Discontinue'
import SuccessMessage from './views/login/SuccessMessage'
import ThemeWrapper from './themes/ThemeWrapper'
import AutoLogoutModal from './components/AutoLogoutModal'

function AuthenticatedApp(props) {
  return (
    <div>
      <Switch>
        <Route exact path='/discontinue' component={Discontinue} />
        <Route exact path='/cancel' component={Cancellation} />
        <Route path='/atmos/change-username/complete' component={SuccessMessage} />
        <Route path='/product-select' component={UpgradeProduct} />
        <Route path='/welcome' component={Onboarding} />
        <Route path='/(implement|implement-changes)' component={Offboarding} />
        <Route path='/' component={Main} />
      </Switch>
    </div>
  )
}

function GatewayApp(props) {
  const { isAuthenticated } = props.store.auth

  return (
    <div>
      {isAuthenticated ? (
        <Route
          path='/'
          render={props => (
            <ThemeWrapper>
              <AuthenticatedApp />
              <AutoLogoutModal />
            </ThemeWrapper>
          )}
        />
      ) : (
        <Route path='/' component={AuthCheck} />
      )}
    </div>
  )
}

export default inject('store')(observer(GatewayApp))
