const IconClock = `<svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
        <path d="M0 0h30v30H0z"/>
        <g fill="#4A606A" fill-rule="nonzero">
            <path d="M14.959 3C8.365 3 3 8.365 3 14.959c0 6.594 5.365 11.959 11.959 11.959 6.594 0 11.958-5.365 11.958-11.96C26.917 8.366 21.553 3 14.96 3zm0 22.203c-5.649 0-10.244-4.595-10.244-10.244 0-5.65 4.595-10.245 10.244-10.245S25.203 9.31 25.203 14.96c0 5.649-4.595 10.244-10.244 10.244z"/>
            <path d="M21.108 14.758h-5.62V8.615a.857.857 0 1 0-1.714 0v7c0 .474.384.858.857.858h6.477a.857.857 0 1 0 0-1.715z"/>
        </g>
    </g>
</svg>`

export default IconClock
