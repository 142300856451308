import { API, AUTH } from '../api'
import { types, flow } from 'mobx-state-tree'
import _ from 'lodash'
const { model, maybeNull, array, string, number, optional } = types

const RegisteringAccount = model({
  emailAddress: maybeNull(string),
  birthDate: maybeNull(string),
  firstName: maybeNull(string),
  lastName: maybeNull(string),
  password: maybeNull(string),
})

const SubscriptionInfo = model({
  name: maybeNull(string),
  paidBy: maybeNull(string),
  price: maybeNull(number),
  interval: maybeNull(string),
  status: maybeNull(string),
  origin: maybeNull(string),
  startDate: maybeNull(string),
  expirationDate: maybeNull(string),
  promotion: maybeNull(string),
})

const PaymentInfo = model({
  id: maybeNull(string),
  type: maybeNull(string),
  lastDigits: maybeNull(string),
})

const RetailStore = model({
  retailRegisteredAccount: optional(RegisteringAccount, {}),
  subscriptions: array(SubscriptionInfo),
  subscription: maybeNull(SubscriptionInfo),
  defaultPaymentInfo: optional(PaymentInfo, {}),
  token: maybeNull(string),
  domain3n: maybeNull(string),
})
  .views(self => ({
    get subscriptionStatus() {
      if (self.subscription) {
        const expirationDate = new Date(self.subscription.expirationDate)
        const now = new Date()
        if (expirationDate < now) {
          return 'expired'
        }
      }
      return self.subscription?.status
    },
    get subscriptionExpiration() {
      if (self.subscription) {
        const expirationDate = new Date(self.subscription.expirationDate)
        return expirationDate.toLocaleDateString()
      }
      return new Date().toLocaleDateString()
    },
  }))
  .actions(self => ({
    setRetailLoginAccount(account) {
      self.loginStore = account
      self.loginStore.username = account.email
    },
    setSubscriptions(subscriptions) {
      self.subscriptions = subscriptions
    },
    selectPricebook: flow(function* (pricebookId) {
      const res = yield API.post(`pricebook/select?pricebookId=${pricebookId}`)
      sessionStorage.setItem('pricebook-id', pricebookId)
      return res
    }),
    clearPricebookSelection() {
      sessionStorage.removeItem('pricebook-id')
    },
    getRetailLoginEmail: flow(function* () {
      yield API.post('/ui/retail/authorize')

      const res = yield AUTH.get('retail/user')
      const data = _.get(res, 'data.username', '')
      self.loginStore.username = data
      return data
    }),
    getSubscriptions: flow(function* () {
      const { data } = yield API.get('subscription/subscription-info')
      self.setSubscriptions(data)
      if (data?.length > 0) {
        self.subscription = data[0]
      }
      return data
    }),
    getDefaultPayment: flow(function* () {
      const paymentMethods = yield API.get('payment')
      const { data } = yield API.get('payment/default-payment')
      const defaultPayment = paymentMethods.data.find(paymentMethod => paymentMethod.id === data)
      self.defaultPaymentInfo = defaultPayment
      return defaultPayment
    }),
    getPricebookId() {
      return sessionStorage.getItem('pricebook-id')
    },
    async discontinueRetailSubscription() {
      await API.delete('subscription/cancel/member')
      await this.getSubscriptions()
    },
    get3nDomain: flow(function* () {
      const { data } = yield API.get('domain/3n')
      self.domain3n = data.domain
      return data.domain
    }),
  }))

export default RetailStore
