/* eslint-disable react/jsx-key */

import { css } from '@emotion/react'
import { inject, observer } from 'mobx-react'
import styled from '@emotion/styled'

import { API_URL } from '../api'
import { numberToDollars } from '../utils'

export const NavLink = styled.div`
  & > a {
    font-size: 1.8rem;
    text-decoration: none;
    line-height: 1.35;
    color: ${p => p.theme.primaryColor};
    font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  }
`
export const PrimaryLink = styled.div`
  & > a {
    font-size: 1.8rem;
    text-decoration: none;
    line-height: 1.35;
    color: ${p => p.theme.primaryColor};
    font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  }
`
export const SecondaryLink = styled.div`
  & > a {
    color: ${p => p.theme.primaryColor};
    text-decoration: none;
    font-family: 'Aleo', 'Helvetica Neue', sans-serif;
    line-height: 1.5;
    font-size: 1.125rem;
  }
`
export const AnswerSection = styled.div`
  margin: 32px 0;
  color: ${p => p.theme.helpTextColor};
`
export const QuestionTitle = styled.div`
  font-weight: bold;
  color: ${p => p.theme.helpTextColor};
`
export const Paragraph = styled.div`
  margin: 20px 0;
  color: ${p => p.theme.helpTextColor};
`
export const Link = styled.a`
  color: ${p => p.theme.primaryColor};
  text-decoration: none;
  font-family: 'Aleo', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  font-size: 1.125rem;
`

const createField = (id = '', link = false, title = '', answer = []) => ({
  id,
  link,
  title,
  answer,
})

const ContributionLimitsForEmployerRelatedAccounts = inject('store')(
  observer(function (props) {
    const { limits } = props.store.plan

    return (
      <div>
        <div>
          You can contribute up to ${numberToDollars(limits.dlr401k, true)} (plus a $
          {numberToDollars(limits.catchupLimitAmount, true)} catch-up contribution for certain
          individuals aged {limits.catchupAge} or over for plan year {limits.year}), unless your
          employer's plan limits you to less. The IRS reviews the limit for adjustment annually. If
          your employer's plan sets lower limits, it's not because they necessarily want to, rather
          it is to ensure they pass certain other tests set up by the regulations.
        </div>
        <Paragraph>
          If your employer offers the Roth 401(k) option, you can choose to participate in the
          401(k) plan only, the Roth 401(k) plan only, or in both. However, the accounts are kept
          separate and the combined total cannot exceed the annual limit (plus any catch-up
          contribution, if allowed). You are not permitted to rollover your Roth 401(k) balance into
          your regular 401(k) — or vice versa.
        </Paragraph>
        <Paragraph>
          Contributions cannot be made to the plan after your pay from all eligible sources reaches
          ${numberToDollars(limits.annualContributionLimit, true)} in {limits.year}. You need to
          make sure you contribute the maximum allowed, either the IRS or plan limit (plus any
          applicable catch-up contributions), whichever is less, before you reach $
          {numberToDollars(limits.annualContributionLimit, true)} in eligible compensation. Eligible
          compensation is defined by your plan.
        </Paragraph>
        <Paragraph>
          Some highly paid employees (as defined by the 401(k) regulations) can be restricted from
          making the maximum contribution. If you are affected by this regulation, your Plan will
          return your excess contributions after the end of the calendar year.
        </Paragraph>
        <Paragraph>
          In addition, there is an overall limit to the amounts you and your employer can contribute
          to your plan accounts. The sum of your contributions and contributions made by your
          employer on your behalf through the 401(k) plan, the profit sharing plan, and the
          after-tax voluntary savings plan cannot exceed 100% of your total compensation or $
          {numberToDollars(limits.overallLimit, true)}, whichever is less. Contributions from your
          employer may include a 401(k) match and/or profit sharing.
        </Paragraph>
      </div>
    )
  })
)
const WhatIsFrontLoadingYourEmployerPlanAccount = inject('store')(
  observer(function (props) {
    return (
      <div>
        <Paragraph>
          Front-loading your employer plan account means you want to contribute at a higher rate now
          so that you can contribute at a lower rate later in the year, e.g., you want more cash in
          your paycheck later in the year for the holidays or you know you are going to quit your
          job and want to contribute as much as you can for the year before you do.
        </Paragraph>
        <Paragraph>
          While this can be an effective strategy, there are a couple cautions to consider so that
          you can make an informed decision.
        </Paragraph>

        <div>
          <ul>
            <li>
              <strong>Matching contribution rules: &nbsp; </strong>
              <span>
                Some plans will make your account “whole” through a provision frequently referred to
                as “top up”, whereby at the end of the year your contributions are treated as though
                they were spread evenly throughout the year, while others will only match during the
                pay periods that the contribution takes place, which may result in a reduced total
                matching contribution from your employer. <br></br>
                <br></br>
                For example: Let's take two employees, both the same age, both earning $100,000 a
                year. Their employer matches 5% of salary. The first employee front-loads his
                balance and maxes out his annual contribution by the end of June. Since he's only
                contributing to his account during the first half of the year, unless there is a top
                up provision for the plan, he would only receive the 5% match on half his income. In
                this case, he would only receive $2,500 in matching contributions from his employer,
                thereby missing out on an additional $2,500.<br></br>
                <br></br>
                On the other hand, the second employee spreads out her contributions throughout the
                year, ensuring a contribution will be made each pay period. She therefore receives
                the 5% match on her entire annual income, or $5,000.<br></br>
                <br></br>
              </span>
            </li>
            <li>
              <strong>Dollar-cost averaging: &nbsp; </strong>
              <span>
                While understanding your plan's matching contribution rules allows you to plan and
                make an informed decision about what you could miss out on, the outcome of using a
                dollar-cost averaging strategy is not. The market is volatile and unpredictable in
                its ups and downs. If you front-load and the market takes a dive, there's more in
                your portfolio to lose; conversely, of course, if the market goes up, there's more
                to gain. By employing a strategy of dollar-cost averaging—investing the same set
                dollar amount over regular intervals over a set period of time—you can better
                protect yourself from those ups and downs. Only in hindsight is there a right or
                wrong answer. You must decide your comfort level with the risk you take of a market
                downturn, thus exposing your portfolio to greater loss. <br></br>
              </span>
            </li>
          </ul>
        </div>
      </div>
    )
  })
)

const ContributionLimitsForIRAs = inject('store')(
  observer(function (props) {
    const { limits } = props.store.plan

    return (
      <div>
        The IRA contribution limit for {limits.year} is ${numberToDollars(limits.iraAmount, true)}
        —or, if age {limits.catchupAge} or over, you can contribute an additional $
        {numberToDollars(limits.catchupIraAmount, true)} in catch-up contributions for a total of $
        {numberToDollars(limits.iraTotalCatchupLimit, true)}. The limit is a per person, not a per
        account limit. In other words, you can have a traditional IRA and a Roth IRA, but your total
        contribution to both cannot exceed the limit.
      </div>
    )
  })
)

export const content = [
  {
    id: 'overall-strategy',
    title: 'Overall Strategy',
    fields: [
      createField('symbols', true, 'What do the symbols mean?', [
        "These are a quick way to see how you're doing in reaching your goal. If you get a green upward arrow, you're doing well and on track. If you see an orange triangle, you are off track and may want to make some changes. You can modify your goal or change your strategy.",
      ]),
      createField(
        'value-difference',
        true,
        "What is the difference between the value at retirement and today's value?",
        [
          "The simple answer is inflation. The value at retirement is inflated. To give perspective on what that means for you in purchasing power, we convert the future inflated dollars to today's value. The more time you have until retirement, the larger the difference you'll see between the two values.",
        ]
      ),
    ],
  },
  {
    id: 'current-strategy',
    title: 'Current Strategy',
    fields: [
      createField('meaning', true, 'What do you mean by Current Strategy?', [
        'Simply put, this is what you are doing today. We use your current balances in your retirement accounts and your current savings rates to calculate the projected replacement income.',
      ]),
      createField(
        'projected-replaces',
        true,
        'What is projected replacement income your current strategy replaces?',
        [
          "GuidedChoice projects the amount of income you can expect from your current investments—your current balance as well as expected future savings. Your retirement assets are simulated assuming an end date from the mortality tables and adding ten years. (Adding ten years provides a similar value to buying an annuity and guaranteeing a lifetime income.) Then we add any income from Social Security benefits, pension plan payouts, and annuity payouts, which are guaranteed for your lifetime (and potentially your spouse's) and subtract income tax to arrive at your projected replacement income. This amount would be your expected spendable income.",
        ]
      ),
      createField(
        'projected-calculated',
        true,
        'How is projected replacement income for your current strategy calculated?',
        [
          "Intuitively, you understand that there is no way for us to know the exact market conditions from now until you retire. But using powerful simulation technology and robust analytics, GuidedChoice projects hundreds of possible market scenarios and then determines their impact on your projected retirement balances. For these, we have to use an end date, which we calculate based on mortality and adding ten years.  (Adding ten years provides a similar value to buying an annuity and guaranteeing a lifetime income.) Then we add your other retirement assets, such as Social Security, pensions and annuities. Last but not least, we take into account taxes, so the number shown is after tax—the amount you'll have to spend in retirement.",
          <Paragraph>
            We then measure the likelihood that any one outcome would occur. The probability of an
            outcome is what we expect the frequency of any one outcome to be after running several
            hundred different scenarios.
            <ul>
              <li>
                On the low end of the range, we display a “weak” market outcome. This is the most
                likely outcome. There is a 95% chance (95 times out of 100) that you'll have at
                least this monthly income after retiring.
              </li>
              <li>
                On the high end, we display a “strong” market outcome. This is the least likely
                outcome. There is a 50% chance (50/50 or 50 times out of 100) that you'll have at
                least this monthly income after retiring.
              </li>
              <li>
                The number displayed below the circle is the “average” market outcome. This amount
                is what we expect as the likely outcome. There is a 66% chance (2 out of 3, or 66
                times out of 100), that you'll have at least this monthly income after retiring.
              </li>
            </ul>
          </Paragraph>,
        ]
      ),
      createField(
        'how-we-calculate-growth',
        true,
        'How do you calculate the investment growth over time?',
        [
          `GuidedChoice starts with your current account balances in any accounts you have included for retirement.  We analyze the current investments to determine the range of potential returns.  Then we run hundreds of simulations using those balances in each investment type and any savings you indicated that you expected to deposit in the future.  The green bar chart shows what you saved to date—the total of current account balances as the lower number.  The top number is the projected total of those balances plus savings at your retirement age, which can be seen in “today's dollars” or in “future dollars.”  The difference between today's dollars and future dollars is the effect of inflation.`,
        ]
      ),
      createField('investment-risk', true, 'What is investment risk?', [
        'All investments have an expected rate of return based on estimates and historical averages. Risk measures the likelihood that your investment will do better or worse than the expected rate. Although most people focus on "down-side" risk—how much they could lose—there is such a thing as "up-side" risk, which is the chance that the investment return will be much greater than expected. The greater the difference between these two points, the greater the volatility. This is also referred to as a "risk/return" trade-off.',
        <Paragraph>
          Based on your goals, we calculate a “preferred range” for risk. Risk is one of the
          variables you can control in the GuidedChoice interface, thus giving you an opportunity to
          assess the impact of investing at different levels of risk. Investments considered to be
          higher risk are generally more likely to experience higher volatility in the short-term.
          You can expect your account balance to vary significantly with higher risk. Historically
          however, these investments have had greater returns over the long term. If an investor has
          a very short investment time horizon, it is usually recommended that they invest in a less
          risky or less volatile portfolio.
        </Paragraph>,
      ]),
      createField('strategy-my-goals', true, 'What do you mean by my goals?', [
        "The first goal you establish on our system is your replacement income goal. This is the big one. But you may want to use money you're saving to fund retirement for other purposes. These other purposes are also goals. So when GuidedChoice looks at your current strategy and how you're doing, we take into account all the goals you've added.",
      ]),
    ],
  },
  {
    id: 'fast-facts',
    title: 'Fast Facts',
    fields: [
      createField('what-are-fast-facts', true, 'What are Fast Facts?', [
        'GuidedChoice provides a quick glance at information we think you might find interesting.  We rotate these based on what we think might be relevant to you.',
        <ul>
          <li>
            <span
              css={css`
                font-style: italic;
              `}>
              Difference in accumulated assets.
            </span>{' '}
            This is the difference between what you're currently doing versus doing what we
            recommend. If there is an increase, that normally comes from a combination of an
            increase in savings as well as an increase in investment returns from being optimized.
            If there is a decrease, it is usually caused by a decrease in risk that we would
            recommend when we optimize your investments. If you are comfortable and understand the
            risk and return trade-offs, you can model and choose to increase your risk from our
            recommendation.
          </li>
          <li>
            <span
              css={css`
                font-style: italic;
              `}>
              Estimated Social Security.
            </span>{' '}
            This will show the estimated monthly income from Social Security and the planning age
            Social Security income starts.
          </li>
          <li>
            <span
              css={css`
                font-style: italic;
              `}>
              Estimated household earned income after tax.
            </span>{' '}
            The amount displayed should be close to what you have to spend each month after taxes.
            The best way to validate this amount is to look at your tax returns and subtract your
            annual tax amount (federal plus state) from your adjusted gross income (AGI) and divide
            by 12.
          </li>
          <li>
            <span
              css={css`
                font-style: italic;
              `}>
              Other financial goals.
            </span>{' '}
            This is a total of the goals you've added above and beyond your retirement monthly
            income goal.
          </li>
          <li>
            <span
              css={css`
                font-style: italic;
              `}>
              Ongoing annual savings.
            </span>{' '}
            This is a total of all savings going into investment accounts annually.
          </li>
        </ul>,
      ]),
    ],
  },
  {
    id: 'scenario-planning',
    title: 'Scenario Planning',
    fields: [
      createField('what-is-scenario-planning', true, 'What is Scenario Planning?', [
        'Once you establish your goals, GuidedChoice calculates a recommended strategy to reach your goals.  You can then change or modify many of the variables to determine your favored path to reach your goals.',
      ]),
      createField('recommended-strategy', true, 'What do you mean by Recommended Strategy?', [
        'Based on your goals, GuidedChoice runs hundreds of scenarios to choose an optimal solution that would enable you to reach your goals.  First, we will optimize your investments to enable you to invest efficiently for the time horizon you choose.  Time horizon is simply from now to retirement and through retirement to the expected end date.  If you can reach your goal with at least a two out of three chance, we stop there. If not, we continue to simulate options in the following order¬—increase tax efficient savings, increase other savings, modify risk within the preferred range, and lastly, change the goal, which could include your time horizon (your retirement age).',
      ]),
      createField(
        'projected-recommended-strategy',
        true,
        'What is the projected replacement income your recommended strategy replaces?',
        [
          "GuidedChoice projects the amount of income you can expect based on our recommendations from your current investments—your current balance as well as future savings. Your retirement assets are simulated assuming an end date from the mortality tables and adding ten years. (Adding ten years provides a similar value to buying an annuity and guaranteeing a lifetime income.) Then we add any income from Social Security benefits, pension plan payouts, and annuity payouts, which are guaranteed for your lifetime (and potentially your spouse's) and subtract income tax to arrive at your projected replacement income. This amount would be your expected spendable income.",
        ]
      ),
      createField(
        'projected-recommended-strategy-calculated',
        true,
        'How is projected replacement income for your recommended strategy calculated?',
        [
          'Intuitively, you understand that there is no way for us to know the exact market conditions from now until you retire. But using powerful simulation technology and robust analytics, GuidedChoice projects hundreds of possible market scenarios and then determines their impact on your projected retirement balances. For these, we have to use an end date, which we calculate based on mortality and adding ten years.  (Adding ten years provides a similar value to buying an annuity and guaranteeing a lifetime income.)',
          <Paragraph>
            First, we will optimize your investments to enable you to invest efficiently for the
            time horizon you choose. Time horizon is simply from now to retirement and through
            retirement to the expected end date. If you can reach your goal with at least a two out
            of three chance, we stop there. If not, we continue to simulate options in the following
            order—increase tax efficient savings, increase other savings, modify risk within the
            preferred range, and lastly, change the goal.
          </Paragraph>,
          <Paragraph>
            Then we add your other retirement assets, such as Social Security, pensions, and
            annuities. Last but not least, we take into account taxes, so the number shown is after
            tax—the amount you'll have to spend in retirement.
          </Paragraph>,
          <Paragraph>
            We then measure the likelihood that any one outcome would occur. The probability of an
            outcome is what we expect the frequency of any one outcome to be after running several
            hundred different scenarios.
            <ul>
              <li>
                On the low end of the range, we display a “weak” market outcome. This is the most
                likely outcome. There is a 95% chance (95 times out of 100) that you'll have at
                least this monthly income after retiring.
              </li>
              <li>
                On the high end, we display a “strong” market outcome. This is the least likely
                outcome. There is a 50% chance (50/50 or 50 times out of 100) that you'll have at
                least this monthly income after retiring.
              </li>
              <li>
                The number displayed below the circle is the “average” market outcome. This amount
                is what we expect as the likely outcome. There is a 66% chance (2 out of 3, or 66
                times out of 100), that you'll have at least this monthly income after retiring.
              </li>
            </ul>
          </Paragraph>,
        ]
      ),
      createField(
        'investment-growth-calculation',
        true,
        'How do you calculate the investment growth over time for the recommended strategy?',
        [
          `GuidedChoice starts with your current account balances in any accounts you have included for retirement.  We optimize investments in any accounts included for the advice.  We analyze the current balances in our recommended portfolio to determine the range of potential returns.  Then we run hundreds of simulations using those balances in each investment type and any current and recommended savings amounts expected to be deposited in the future.  The green bar chart shows what you saved to date—the total of current account balances as the lower number.  The top number is the projected total of those balances (advised accounts are assumed to be in an optimal portfolio) plus the recommended savings at your retirement age, which can be seen in “today's dollars” or in “future dollars.”  The difference between today's dollars and future dollars is the effect of inflation.`,
        ]
      ),
      createField('how-to-get-there', true, 'How to get there?', [
        'On the right-hand side, we provide an outline of the recommendation.',
        <Paragraph>
          Qualified Plan Contributions: The first item shown will be the recommended savings rates
          inside a qualified plan. They may be broken out by the type of allowable savings—pre-tax,
          after-tax, and/or Roth.
        </Paragraph>,
        <Paragraph>
          Additional Annual Savings: If you can't reach your goal by saving enough within qualified
          plans, GuidedChoice will recommend additional savings. It is important to note that the
          amount shown here is in addition not only to the amount saved within a qualified plan, but
          also to those amounts you indicated a commitment to ongoing annual contributions.
        </Paragraph>,
        <Paragraph>
          Risk: GuidedChoice will simulate looking for an optimal risk/return trade-off. We combine
          different kinds of assets, such as stocks, bonds, real estate, and cash, to create an
          investment strategy designed to help you reach your goals while limiting your exposure to
          risk. Our preferred range pertains to how we'd prefer you invest, not only in a current
          employer retirement plan account, but in all accounts for which you indicated you would
          like GuidedChoice to provide investment advice. There may be times when savings
          limitations will require a risk level above our preferred range in order for you to reach
          your goals. Alternatively, there may be situations where you can reduce your risk to reach
          your goals given you have been or will be saving adequate amounts.
        </Paragraph>,
        <Paragraph>
          Retirement Age: You select your retirement age when establishing your goals. If a savings
          trajectory won't get you to your retirement goals, we may need to adjust your retirement
          age.
        </Paragraph>,
      ]),
      createField('making-changes', true, 'Making changes', [
        "Here's where the scenario planning really comes into play. When you click this button, a page opens which contains all the variables you can adjust and get new results. When you click to recalculate, you're brought back to the Scenario Planning area, but now you can compare the GuidedChoice recommendation to what was modified.",
        <Paragraph>
          If you want, you can save a scenario so that you can go back to it and even compare it
          with others…
        </Paragraph>,
      ]),
      createField('comparing-saved-scenarios', true, 'Comparing saved scenarios', [
        "Let's say you want to know if you can afford a particular goal, e.g. a new car in three years, costing an estimated $50,000. In one scenario you save, you include this new car goal. But in another scenario you save, you exclude this new car goal. If the inclusion/exclusion of this goal is the only variable that's different, you can now see very clearly the difference in your retirement income, and thus whether or not you can afford your new $50,000 car.",
      ]),
    ],
  },
  {
    id: 'goals',
    title: 'Goals',
    fields: [
      createField(
        'percent-income-seeking',
        true,
        'What is percent of income seeking after retirement?',
        [
          'Most financial planners would say you need somewhere between 70-90% of your pre-retirement income during retirement, with 80% being the most commonly used amount for planning purposes.  Another way to look at it is to use our worksheet under Tools. You can determine how much you need to live off of in retirement based on your current expenditures.  Be sure to consider whether your debt will be paid off before you retire or during retirement.  This would include your home mortgage, any large purchases, such as a car, and any credit card debt.',
        ]
      ),
      createField(
        'costs-surrounding-health-and-medical-care',
        true,
        'What can I expect in terms of costs surrounding health and medical care after retiring?',
        [
          'There are lots of studies showing what kinds of costs you can expect. Because while Medicare will cover a lot of expenses, you still need to consider deductibles, premiums, prescription drug, and other out-of-pocket expenses. A popular statistic from Fidelity estimates that the average couple can expect to pay $245,000 in health care expenses alone during their retirement years—and that was in 2015, which showed an increase of 11% from 2014. Costs continue to rise due longer life expectancies, as well as anticipated increases in health care and prescription drug expenses.',
        ]
      ),
      createField('my-goals', true, 'What do you mean by my goals?', [
        "The first goal you establish on our system is your replacement income goal. This is the big one. But you may want to use money you're saving to fund retirement for other purposes. These other purposes are also goals. So when GuidedChoice looks at your current strategy and how you're doing, we take into account all the goals you've added.",
      ]),
      createField('add-a-new-goal', true, 'How do I add a new goal?', [
        "To pay for expenditures beyond your regular retirement income, you can create another goal.  Just click on + Add a new goal.  You can name the goal, choose whether it is a one-time amount or an annual amount for multiple years.  Enter the year(s) and an estimate of the future amount needed. For example, let's say you'd like a new car every ten years starting at age 67.  Establish a goal of $25,000 at age 67, $30,000 at age 77, and $35,000 at age 87, etc.",
      ]),
    ],
  },
  {
    id: 'accounts',
    title: 'Accounts',
    fields: [
      createField(
        'employer-related-accounts',
        true,
        'What are employer-related (sponsored) accounts?',
        [
          'These are retirement accounts that are provided by your employer, such as 401(k), 457, 403(b) and 401(a), Keogh, and pension plans.',
        ]
      ),
      createField('other-types-accounts', true, 'What are other types of accounts?', [
        'Other accounts would include personal IRAs, brokerage accounts, or other assets.',
      ]),
      createField(
        'contribution-limits-for-employer-related-accounts',
        true,
        'What are the contribution limits for employer-related accounts (e.g. 401(k), 403(b), 457)?',
        [<ContributionLimitsForEmployerRelatedAccounts />]
      ),
      createField(
        'what-is-front-loading-your-employer-plan-account',
        true,
        'What is front-loading your employer plan account?',
        [<WhatIsFrontLoadingYourEmployerPlanAccount />]
      ),
      createField(
        'contribution-limits-for-IRAs',
        true,
        'What are the contribution limits for IRAs?',
        [<ContributionLimitsForIRAs />]
      ),
      createField('add-new-account', true, 'How do I add a new account?', [
        'To add a new account, click on + Add a new account.  You can select the type of account from the menu provided.  Then follow the screens to provide information about the account.',
        <Paragraph
          css={css`
            padding-left: 24px;
          `}>
          <QuestionTitle>Let's get some account basics</QuestionTitle>
          <span
            css={css`
              font-style: italic;
            `}>
            Give the account a name.{' '}
          </span>
          You can choose any name that will help you identify the account.
        </Paragraph>,
        <Paragraph
          css={css`
            padding-left: 24px;
          `}>
          <span
            css={css`
              font-style: italic;
            `}>
            Who owns the account.{' '}
          </span>
          We assume the account is yours unless you are planning with a spouse, in which case, we
          will ask you to indicate whose account it is.
        </Paragraph>,
        <Paragraph
          css={css`
            padding-left: 24px;
          `}>
          <span
            css={css`
              font-style: italic;
            `}>
            Would you also like investment advice for this account.{' '}
          </span>
          You can choose on which accounts you receive advice. If the advice is being provided in
          conjunction with an employer plan, that account will automatically be included to receive
          an investment allocation. For all other accounts, you choose. Keep in mind, you should be
          willing to make changes to the investments in those accounts which you identify advice is
          to be included, as our projections make the assumption you will implement our advised
          allocation.
        </Paragraph>,
        <Paragraph
          css={css`
            padding-left: 24px;
          `}>
          <QuestionTitle>For employer-related accounts:</QuestionTitle>
        </Paragraph>,
        <Paragraph
          css={css`
            padding-left: 24px;
          `}>
          <QuestionTitle>Let's look at paycheck contributions</QuestionTitle>
        </Paragraph>,
        <Paragraph
          css={css`
            padding-left: 24px;
          `}>
          <span
            css={css`
              font-style: italic;
            `}>
            Is the account owner eligible to make contributions?{' '}
          </span>
          If the owner of the account is employed and eligible for the plan, choose yes. Otherwise,
          select no.
        </Paragraph>,
        <Paragraph
          css={css`
            padding-left: 24px;
          `}>
          The other questions are asking about the types of allowed contributions. Most, if not all,
          retirement plans allow for pre-tax contributions or savings, but some will also allow
          other types of contributions or savings, such as Roth or after-tax.
        </Paragraph>,
        <Paragraph
          css={css`
            padding-left: 24px;
          `}>
          <QuestionTitle>Let's look at employer contributions</QuestionTitle>
        </Paragraph>,
        <Paragraph
          css={css`
            padding-left: 24px;
          `}>
          We will need to know if the employer makes matching contributions and/or whether a profit
          sharing component is included. For matching contributions, we ask about the employer's
          formula and maximum. If you are unsure of the match formula, contact the plan
          administrator. For profit sharing, we understand this amount varies based on the profits
          of the company. Use an estimate based on expected annual contributions.
        </Paragraph>,
        <Paragraph
          css={css`
            padding-left: 24px;
          `}>
          <QuestionTitle>Let's add an investment</QuestionTitle>
        </Paragraph>,
        <Paragraph
          css={css`
            padding-left: 24px;
          `}>
          You want to enter all the investment options available in an employer related plan, even
          if you're not invested currently in that option. We'll be selecting investments from the
          list to provide a recommended allocation for that account. For stocks, mutual funds and
          ETFs, you can search for the investment using the ticker symbol or name. If the investment
          currently has a balance in the account, then add the balance either using the number of
          shares you hold or the balance.
        </Paragraph>,
        <Paragraph
          css={css`
            padding-left: 24px;
          `}>
          <QuestionTitle>For other types of accounts</QuestionTitle>
        </Paragraph>,
        <Paragraph
          css={css`
            padding-left: 24px;
          `}>
          <QuestionTitle>Let's look at contributions</QuestionTitle>
          Enter the amount the owner is expecting to contribute every year to the account. If you
          are not expecting to save in this account annually until retirement, you may not want to
          include the savings amounts, as it will result in an overstatement of projected income.
        </Paragraph>,
      ]),
      createField('delete-account', true, 'How do I delete an account?', [
        'On the Accounts page, all of the accounts are listed.  There is a circle with an x in it.  Click on the x, and you will have an option to delete the account.',
      ]),
      createField(
        'account-aggregation',
        true,
        'What is account aggregation and do I have access?',
        [
          <Paragraph>
            Some GuidedChoice clients have access to the account aggregation services of Plaid, Inc.
            Account aggregation makes it possible to electronically update investment account
            information for those accounts for which you provide login credentials. Your account
            credentials are verified and kept by Plaid; GuidedChoice has no access to your account.
          </Paragraph>,
          <Paragraph>
            If account aggregation services are available to you, you will be given the option to
            sign up for those services when adding account investments, as well as when updating the
            investments of each account you've added. If you wish to see details of the process,
            download the{' '}
            <Link
              target='_blank'
              href={`${API_URL}general-documents/Account%20Aggregation%20Guide.pdf`}
              rel='noreferrer'>
              Account Aggregation Guide (PDF)
            </Link>
            .
          </Paragraph>,
        ]
      ),
    ],
  },
  {
    id: 'privacy-policy',
    title: 'Privacy Policy',
    fields: [
      createField('privacy-policy', false, '', [
        'The GuidedChoice Privacy Policy can be viewed on our web site at: ',
        <Link target='_blank' href='https://www.guidedchoice.com/legal' rel='noreferrer'>
          https://www.guidedchoice.com/legal
        </Link>,
      ]),
    ],
  },
]
