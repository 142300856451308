import { Component } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const ModalBox = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 199;
`

const ModalContent = styled.div`
  position: relative;
  padding: 2rem;
  margin: 1.5rem;
  border: 1px solid #979797;
  width: 100%;
  max-width: 640px;
  max-height: 480px;
  background-color: white;
  padding: 30px;
`

const Title = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2.5rem;
  line-height: 50px;
  font-weight: 300;
  text-align: left;
  color: ${p => p.theme.mediumGray};
  max-width: 500px;
  transition: 0.8s;
  height: 80px;
  padding-top: 10px;
  @media (max-width: 520px) {
    font-size: 2rem;
    line-height: 42px;
  }
`

const XButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 10px;
  color: ${p => p.theme.lightestGray};
  font-size: 50px;
  line-height: 30px;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
`

export default class SharedModal extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool,
    toggleModal: PropTypes.func.isRequired,
    title: PropTypes.string,
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto'
  }

  render() {
    const { isModalOpen, toggleModal, title, children, maxHeight } = this.props
    const heightStyle = { maxHeight: maxHeight ? maxHeight + 'px' : 480 + 'px' }

    if (isModalOpen) {
      document.body.style.overflow = 'hidden'
      return (
        <ModalBox>
          <ModalContent style={heightStyle}>
            <XButton onClick={toggleModal}>&times;</XButton>
            <Title>{title}</Title>
            {children}
          </ModalContent>
        </ModalBox>
      )
    } else {
      document.body.style.overflow = 'auto'
      return null
    }
  }
}
