import { Drawer, Button, SVGWrapper, Spacer } from '../../../../components'
import { Page } from '../../elements'
import LogoGcSmall from '../../../../assets/images/LogoGcSmall.png'
import Logo3NSmall from '../../../../assets/images/Logo3NSmall.png'
import Logo3nUni from '../../../../assets/images/Logo3nUni.png'
import {
  ArtPieChart,
  ArtPlant,
  ArtRetirementSmall,
  ArtStudyMaterials,
  BulletInvestment,
  BulletLoans,
  BulletPiggyBank,
  BulletStatistics,
  Bullet3nUniCoach,
  Bullet3nUniLessons,
  Bullet3nUniQA,
  BulletCalculator,
  BulletChecklist,
  BulletBook,
} from '../../../../assets/icons'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'

const formatDollars = amount => {
  if (amount === 0) return '$0'
  const formatter = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' })
  return formatter.format(amount)
}

const LearnMoreDrawer = ({ drawerOpen, setDrawerOpen, pricebookBundle }) => {
  return (
    <Drawer active={drawerOpen} width='700px'>
      <LearnMoreContent pricebookBundle={pricebookBundle} />
      <Button
        secondary
        label='Close'
        size='h41'
        width='85px'
        onClick={() => setDrawerOpen(false)}
      />
    </Drawer>
  )
}

const LearnMoreContent = ({ pricebookBundle }) => {
  if (!pricebookBundle) return null

  return (
    <>
      <Header pricebookBundle={pricebookBundle} />
      <BundleCard pricebookBundle={pricebookBundle} />
      <Description pricebookBundle={pricebookBundle} />
      <Bullets pricebookBundle={pricebookBundle} />
      <ExtraExplainer pricebookBundle={pricebookBundle} />
    </>
  )
}

const Header = ({ pricebookBundle }) => {
  const { productType, pricebookName } = pricebookBundle?.pricebook ?? undefined
  const logo =
    productType === 'GUIDED_CHOICE'
      ? LogoGcSmall
      : productType === 'NICKELSU'
      ? Logo3nUni
      : Logo3NSmall

  return (
    <Row>
      <Column style={{ width: '90%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}>
          <Page.WhoPaysText>
            {productType === 'GUIDED_CHOICE' ? 'GuidedChoice packages' : '3Nickels packages'}
          </Page.WhoPaysText>
        </div>
        <Spacer space='5px' />
        <Page.PricebookNameText color='secondary' variant='p30Light'>
          {pricebookName}
        </Page.PricebookNameText>
        <Spacer space='5px' />
      </Column>
      <img src={logo} alt='logo' style={{ height: '40px' }} />
    </Row>
  )
}

const BundleCard = ({ pricebookBundle }) => {
  const pricebook = pricebookBundle.prices[0]
  const orgPays = pricebookBundle.pricebook.paidBy === 'ORG'
  const promotion = pricebookBundle.prices.find(price => price.introductionPeriodInDays > 1)
  const introPeriodInMonths = Math.floor((promotion?.introductionPeriodInDays ?? 0) / 30)
  const freeTrial30Day = pricebookBundle.pricebook.metadata?.freeTrial30Day === 'true'
  const price = pricebook.price / 100.0

  return (
    <>
      <Spacer space='5px' />
      <Page.BundleCard>
        <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <img
            src={pricebookBundle.pricebook.artUrl}
            alt='Product Art'
            style={{ height: '80px' }}
          />
          <Column style={{ width: '40%', margin: '10px' }}>
            {pricebookBundle.pricebook.description.map((bullet, index) => (
              <Row key={index}>
                <div style={{ marginRight: '20px' }}>•</div>
                <div>{bullet}</div>
              </Row>
            ))}
          </Column>
          <Page.InnerCard style={{ width: '180px' }}>
            <Column style={{ alignItems: 'center' }}>
              {orgPays ? (
                <Page.OrgPaysText>
                  paid by your <br /> organization
                </Page.OrgPaysText>
              ) : (
                <>
                  <Page.PricebookSubHeading>
                    {freeTrial30Day ? `30 days free` : formatDollars(price)}
                    <span>
                      {pricebook.intervalType !== 'NONE' && !freeTrial30Day
                        ? '/' + pricebook.intervalType
                        : ''}
                    </span>
                  </Page.PricebookSubHeading>
                  <Spacer space='5px' />
                  <div
                    css={css`
                      align-self: center;
                      font-size: 12px;
                    `}>
                    {freeTrial30Day
                      ? `then $14.99/month`
                      : pricebook.price === 0
                      ? 'free'
                      : pricebook.intervalType === 'MONTH'
                      ? `auto-renews monthly`
                      : pricebook.intervalType === 'YEAR'
                      ? `auto-renews annually`
                      : `one-time payment`}
                  </div>
                </>
              )}
            </Column>
          </Page.InnerCard>
        </Row>
      </Page.BundleCard>
      {promotion &&
        (introPeriodInMonths === 1 ? (
          <div>* After 30 days, Advice subscription auto-renews at $14.99/month</div>
        ) : (
          <div>
            * After {introPeriodInMonths} months, Advice subscription auto-renews at $14.99/month
          </div>
        ))}
    </>
  )
}

const Description = ({ pricebookBundle }) => {
  const [state, setState] = useState(undefined)
  const productType = pricebookBundle.pricebook.productType
  const pricebookName = pricebookBundle.pricebook.pricebookName

  useEffect(() => {
    if (!productType) return

    if (productType === 'GUIDED_CHOICE') {
      setState({
        art: ArtRetirementSmall,
        descriptionText: `One of the questions we’re asked the most is how to create a safe after-retirement income plan. That’s why we’ve put our expertise to work on personalized planning everyone near retirement or already retired can afford, and use to make confident decisions.`,
      })
    } else if (productType === 'NICKELSU') {
      setState({
        art: ArtStudyMaterials,
        descriptionText: `No matter where you're at on your journey, we're all about setting you up for success. 3NickelsU is a program that teaches you exactly how to get on the path to financial freedom. Just a few hours can set you up for life!`,
      })
    } else if (productType === 'NICKELS') {
      if (pricebookName === '3Nickels Advice') {
        setState({
          art: ArtPieChart,
          descriptionText: `Experience The Financial Advisor In Your Pocket\u00AE!`,
          extraDescription: `Connect all of your finances in one place and let us do the heavy lifting. We've been advising people on their finances for over 20 years, backed by our sophisticated, award-winning technology. Now it's in your hands!`,
        })
      } else {
        setState({
          art: ArtPlant,
          descriptionText: `Get answers to your basic financial questions at no cost to you. Work through different aspects of your finances, one at a time.`,
        })
      }
    }
  }, [pricebookName, productType])

  if (!state) return null
  return (
    <>
      <Spacer space='5px' />
      <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Column style={{ width: '80%' }}>
          <Page.DescriptionTitleText>Description</Page.DescriptionTitleText>
          <Page.DescriptionText>{state.descriptionText}</Page.DescriptionText>
          {state.extraDescription && (
            <>
              <Spacer space='8px' />
              <Page.DescriptionText>{state.extraDescription}</Page.DescriptionText>
            </>
          )}
        </Column>
        <SVGWrapper svg={state.art} />
      </Row>
    </>
  )
}

const Bullets = ({ pricebookBundle }) => {
  const [bullets, setBullets] = useState(undefined)
  const productType = pricebookBundle.pricebook.productType
  const pricebookName = pricebookBundle.pricebook.pricebookName

  useEffect(() => {
    if (!productType) return

    if (productType === 'GUIDED_CHOICE') {
      setBullets([
        <BulletLine
          art={BulletPiggyBank}
          text={
            <div style={{ fontSize: 16 }}>
              Know exactly how much to <strong>save</strong>, across your accounts, to reach your
              retirement goals
            </div>
          }
        />,
        <BulletLine
          art={BulletInvestment}
          text={
            <div style={{ fontSize: 16 }}>
              Know exactly what to <strong>invest</strong> in and at what percent
            </div>
          }
        />,
        <BulletLine
          art={BulletStatistics}
          text={
            <div style={{ fontSize: 16 }}>
              <strong>Retirement income projections</strong> for a weak, average, and strong market
            </div>
          }
        />,
        <BulletLine
          art={BulletLoans}
          text={
            <div style={{ fontSize: 16 }}>
              <strong>Drawdown recommendations</strong> after retirement
            </div>
          }
        />,
      ])
    } else if (productType === 'NICKELSU') {
      setBullets([
        <BulletLine
          art={Bullet3nUniCoach}
          text={
            <div style={{ fontSize: 16 }}>
              Personalized one-on-one <strong>coaching</strong>
            </div>
          }
        />,
        <BulletLine
          art={Bullet3nUniLessons}
          text={
            <div style={{ fontSize: 16 }}>
              30 quick <strong>lessons</strong>
            </div>
          }
        />,
        <BulletLine
          art={Bullet3nUniQA}
          text={
            <div style={{ fontSize: 16 }}>
              Live <strong>Q&amp;A</strong>
            </div>
          }
        />,
      ])
    } else if (productType === 'NICKELS') {
      if (pricebookName === '3Nickels Advice') {
        setBullets([
          <BulletLine
            art={BulletBook}
            text={
              <div style={{ fontSize: 16 }}>
                <strong>Accessible education</strong> at every level of financial literacy.
              </div>
            }
          />,
          <BulletLine
            art={BulletCalculator}
            text={
              <div style={{ fontSize: 16 }}>
                <strong>Customizable tools</strong> to help you with budgeting, retirement, debt
                payoff and much more.
              </div>
            }
          />,
          <BulletLine
            art={BulletChecklist}
            text={
              <div style={{ fontSize: 16 }}>
                <strong>Actionable financial planning</strong> to help you reach your goals.
              </div>
            }
          />,
          <BulletLine
            art={BulletInvestment}
            text={
              <div style={{ fontSize: 16 }}>
                <strong>Objective investment advice</strong> for each of your personal accounts.
              </div>
            }
          />,
        ])
      } else {
        setBullets([
          <BulletLine
            art={BulletBook}
            text={
              <div style={{ fontSize: 16 }}>
                <strong>Accessible education</strong> at an introductory level.
              </div>
            }
          />,
          <BulletLine
            art={BulletCalculator}
            text={
              <div style={{ fontSize: 16 }}>
                <strong>Calculators and checklists</strong> to help you answer one-off questions.
              </div>
            }
          />,
        ])
      }
    }
  }, [pricebookName, productType])

  if (!bullets) return null
  return (
    <>
      <Spacer space='10px' />
      <Page.DescriptionTitleText>At a glance...</Page.DescriptionTitleText>
      <Spacer space='5px' />
      {bullets}
    </>
  )
}

const BulletLine = ({ art, text }) => {
  return (
    <Row style={{ alignItems: 'center', marginBottom: '10px' }}>
      <SVGWrapper svg={art} />
      <Spacer space='5px' />
      <Page.PackageBulletText>{text}</Page.PackageBulletText>
      <Spacer space='5px' />
    </Row>
  )
}

const ExtraExplainer = ({ pricebookBundle }) => {
  const productType = pricebookBundle.pricebook.productType
  const subscriptionMonths = pricebookBundle.pricebook.metadata?.subscriptionMonths ?? 'two'
  const eduPricebook = pricebookBundle.pricebook.description.some(item => item.includes('.edu'))
  const accessToAdviceText = eduPricebook
    ? 'This promotion offers you access to the Advice tier of 3Nickels.'
    : `For ${subscriptionMonths} month${subscriptionMonths === 1 ? '' : 's'}, you have access to
the Advice tier of 3Nickels.`

  if (productType === 'GUIDED_CHOICE' || productType === 'NICKELS') return null

  return (
    <>
      <Spacer space='7px' />
      <Page.DescriptionTitleText>And you have access to 3Nickels Advice!</Page.DescriptionTitleText>
      <Spacer space='5px' />
      <Row>
        <SVGWrapper svg={ArtPieChart} />
        <Column>
          <Page.DescriptionText>
            {accessToAdviceText} Experience The Financial Advisor In Your Pocket&#0174;!
          </Page.DescriptionText>
          <Spacer space='5px' />
          <Page.DescriptionText>
            Connect all of your finances in one place and let us do the heavy lifting.
          </Page.DescriptionText>
        </Column>
      </Row>
      <Spacer space='10px' />
    </>
  )
}

const Row = ({ style, children }) => {
  return <div style={{ display: 'flex', flexDirection: 'row', ...style }}>{children}</div>
}

const Column = ({ style, children }) => {
  return <div style={{ display: 'flex', flexDirection: 'column', ...style }}>{children}</div>
}

export default LearnMoreDrawer
