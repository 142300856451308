import { withRouter } from 'react-router'
import styled from '@emotion/styled'

const LogoutContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LogoutBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 1 500px;
  box-shadow: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);
  padding: 20px;
  margin: 15px;
  min-height: 150px;
  height: 150px;
`

LogoutBox.Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.5;
  margin: 10px;
  font-weight: bold;
`


function Logout({ history, errorMessage }) {
  return (
    <LogoutContainer>
      <LogoutBox>
        {errorMessage?.length > 0 && <LogoutBox.Text>{errorMessage}</LogoutBox.Text>}
        <LogoutBox.Text>You've been logged out!</LogoutBox.Text>
      </LogoutBox>
    </LogoutContainer>
  )
}

export default withRouter(Logout)
