import { Component } from 'react'
import styled from '@emotion/styled'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { API, API_URL } from '../api'
import SharedModal from './SharedModal'
import Button from './Button'
import { WARNING_BUFFER, AUTO_LOGOUT_TIME } from '../models/Auth'
import { IconClock } from '../assets/icons'
import SVGWrapper from './SVGWrapper'
import _ from 'lodash'

const WarningText = styled.div`
  color: ${props => props.theme.mediumGray};
  margin: 5px 0;
`

const TimeCardWrapper = styled.div`
  background-color: ${props => props.theme.ternaryColor};
  color: ${props => props.theme.mediumGray};
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
`

const IconContainer = styled.span`
  margin-right: 8px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

class AutoLogoutModal extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
  }

  state = {
    showWarning: false,
    timeLeft: WARNING_BUFFER / 1000,
  }

  intervalId = null

  componentDidMount() {
    this.startCheck()
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
    if (this.handleKeepWorking.cancel) {
      this.handleKeepWorking.cancel()
    }
  }

  startCheck = () => {
    this.intervalId = setInterval(() => {
      const timeouts = window.timeouts || {}

      if (_.isEmpty(timeouts)) {
        return
      }

      const latestTimeout = Object.values(timeouts).reduce((latest, timeout) => {
        return !latest || timeout.timestamp > latest.timestamp ? timeout : latest
      }, null)

      if (latestTimeout) {
        const timeoutTime = latestTimeout.timestamp.getTime() + AUTO_LOGOUT_TIME
        const timeRemaining = timeoutTime - new Date().getTime()

        if (timeRemaining <= WARNING_BUFFER && !this.state.showWarning) {
          this.setState({
            showWarning: true,
            timeLeft: Math.floor(timeRemaining / 1000),
          })
        } else if (this.state.showWarning) {
          this.setState({
            timeLeft: Math.max(0, Math.floor(timeRemaining / 1000)),
          })
        }
      }
    }, 1000)
  }

  handleKeepWorking = _.debounce(
    async () => {
      try {
        await API.get('authTracker')

        this.setState({ showWarning: false })
      } catch (error) {
        console.error('Error refreshing session:', error)

        if (error.response?.status === 403) {
          this.props.store.auth.invalidate()
        }
      }
    },

    1000,
    { leading: true }
  )

  handleLogout = () => {
    window.open(`${API_URL.slice(0, -4)}logout`, '_self')
  }

  formatTime = seconds => {
    const mins = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${mins}:${secs.toString().padStart(2, '0')}`
  }

  render() {
    const { showWarning, timeLeft } = this.state

    return (
      <SharedModal
        isModalOpen={showWarning}
        toggleModal={this.handleKeepWorking}
        title='Are you still working?'>
        <WarningText>Your session is about to expire due to inactivity.</WarningText>

        <TimeCardWrapper>
          <div>
            <IconContainer>
              <SVGWrapper
                svg={IconClock}
                fill='#4A606A'
                stroke='#4A606A'
                size='large'
                cursor='default'
              />
            </IconContainer>
            Time before auto-logout:
          </div>
          <div style={{ fontWeight: 'bold' }}>{this.formatTime(timeLeft)}</div>
        </TimeCardWrapper>

        <ButtonContainer>
          <Button secondary width='140px' onClick={this.handleLogout} label='Logout' />
          <Button primary width='140px' onClick={this.handleKeepWorking} label='Keep Working' />
        </ButtonContainer>
      </SharedModal>
    )
  }
}

export default inject('store')(observer(AutoLogoutModal))
